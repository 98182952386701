import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import Button from "../../buttons/buttons";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import { FeaturePaymentModal } from "./featurePayment";
import { getTeamFromCookies } from "../../../utils/Auth";
import {
  storeFeaturedProduct,
  estimateFeaturedRate,
} from "../../../redux/feature/featureActions";

export default React.forwardRef(function FeatureFoodModal(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getAmount: (data) => getProductAmount(data),
  }));

  const dispatch = useDispatch();
  const { id } = useParams();
  const team = getTeamFromCookies();
  let business_id = team;

  const [isOpen, setIsOpen] = React.useState(visibility);
  const [product_amount, setProductAmount] = React.useState();
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [rate, setRate] = useState(0);
  let [totalPrice, setTotalPrice] = useState("");
  let [reference_id, setReferenceId] = useState("");
  const [input, setInput] = useState({
    food_id: id,
    featured_duration_in_hours: "",
  });

  const { food_id, featured_duration_in_hours } = input;
  const { isLoading, featureRate } = useSelector((state) => state.feature);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
    setRate(0);
  }

  function getProductAmount(data) {
    setProductAmount(data);
  }

  useEffect(() => {
    setRate(featureRate?.featured_amount ?? 0);
  }, [featureRate]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
    dispatch(
      estimateFeaturedRate({
        product_amount: product_amount,
        featured_duration_in_hours: Number(value * 24),
      })
    );
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (food_id && featured_duration_in_hours) {
      let convert_to_hours = Number(featured_duration_in_hours * 24);
      try {
        const response = await dispatch(
          storeFeaturedProduct({
            ...input,
            featured_duration_in_hours: convert_to_hours,
            business_id: business_id,
          })
        );
        if (response) {
          closeModal();
          setInput({
            food_id: id,
            featured_duration_in_hours: "",
          });
          setOpen(true);
          setTotalPrice(response?.data?.amount);
          setReferenceId(response?.data?.id);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[415px] sm:w-[500px] bg-white dark:bg-[#121212] rounded-3xl py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                    <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                      <button type="button" onClick={closeModal}>
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="sm:flex justify-center">
                      <div className="text-center sm:text-center mt-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-bold text-black dark:text-white"
                        >
                          Feature Product
                        </Dialog.Title>
                      </div>
                    </div>

                    <div className="border-b-2 mt-4"></div>
                    <div className="mt-5 sm:mt-7 sm:px-8 p-4">
                      <form>
                        <Input.Select
                          title={"How many days will you like to feature?"}
                          name="featured_duration_in_hours"
                          id="featured_duration_in_hours"
                          htmlFor={"featured_duration_in_hours"}
                          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                          onChange={(e) => handleChange(e)}
                        >
                          <option>Select number of days</option>
                          <option value={1}>1 day</option>
                          <option value={7}>1 week</option>
                          <option value={14}>2 weeks</option>
                          <option value={30}>1 month (30 days)</option>
                        </Input.Select>
                        {submitted && !featured_duration_in_hours && (
                          <div className="text-xs text-red-500">
                            Number of days is required
                          </div>
                        )}

                        <Input.Number
                          title={"Cost"}
                          name="cost"
                          id="cost"
                          htmlFor={"cost"}
                          className="w-full bg-[#F3F3F3]"
                          placeholder={"0"}
                          value={rate}
                          disabled
                        />

                        <div className="flex gap-10 mt-8">
                          <Button.Secondary
                            title={"Cancel"}
                            className="w-full"
                            onClick={closeModal}
                          />
                          <Button.Primary
                            title={
                              isLoading ? (
                                <Beat color={"#FFFFFF"} />
                              ) : (
                                "Feature Product"
                              )
                            }
                            disabled={isLoading}
                            type={"button"}
                            className="w-full"
                            onClick={handleSubmit}
                          />
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
      <FeaturePaymentModal
        amount={totalPrice}
        reference_id={reference_id}
        isOpen={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
});
