import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "../../../../../components/cards/admin/cards";
import Table from "../../../../../components/tables/tableCols1";
import { Pagination } from "../../../../../components/pagination/pagination";
import TableSkeleton from "../../../../../components/skeleton/table";
// import Button from "../../../../components/buttons/buttons";
import {
  getTeamFromCookies,
  getOutletFromCookies,
} from "../../../../../utils/Auth";
import { useNavigate } from "react-router-dom";
import { getAllAttributesAction } from "../../../../../redux/attribute/attributeActions";
import CreateFoodVariationModal from "./../../../../../components/modals/admin/attributes/createFoodVariationModal";
import Button from "../../../../../components/buttons/buttons";
import Settings from "../../../../../assets/images/icons/settingsEmpty.svg";

export default function RestaurantAttribute() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const team = getTeamFromCookies();
  const outlet = getOutletFromCookies();
  const { page } = useParams();

  const { allAttributes, isLoading } = useSelector((state) => state.attribute);
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const restaurantAttributes = allAttributes?.data?.filter((item) => {
    return item.business_id === team && item.type === "restaurant";
  });

  useEffect(() => {
    dispatch(getAllAttributesAction(page));
  }, [dispatch, page]);

  function createTableData() {
    let dataToMap = restaurantAttributes;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((attribute, index) => {
        let single = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: attribute.name,
          title: attribute.title,
          value: attribute.amount,
          attribute: attribute,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData();
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAttributes]);

  const goToSinglePage = (params) => {
    let id = params?.single?.attribute?.id;
    navigate(
      `/admin/${
        outlet?.restaurant_name || outlet?.store_name
      }/settings/single-attribute/${id}`
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        columns: [
          {
            Header: "S/N",
            accessor: "single.num",
          },
          {
            Header: "Title",
            accessor: "single.title",
          },
          {
            Header: "Name",
            accessor: "single.name",
          },
          {
            Header: "Value",
            accessor: "single.value",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <Card.AttributeFilter
        results={restaurantAttributes?.length}
        totalResults={restaurantAttributes?.length}
      >
        <Button.Primary
          onClick={openModal}
          className="rounded-sm"
          title={" + Add new Attributes"}
        />
      </Card.AttributeFilter>
      <CreateFoodVariationModal show={isOpen} onClose={closeModal} />
      <div className="border-b border-[#E4E4F3]"></div>
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
        {data?.length > 0 ? (
          <>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                columns={columns}
                data={data?.length > 0 ? data : []}
                onClick={goToSinglePage}
                className="w-full"
                rowClass="hover:shadow-md cursor-pointer"
              />
            )}
          </>
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={Settings} alt="settinga" />
            </div>
            <p className="font-bold text-xl my-3">Settings</p>
            <p className="text-sm text-gray-500">
              You do not have any Attributes yet
            </p>
          </div>
        )}

        <Pagination
          data={allAttributes}
          route={`admin/${outlet?.restaurant_name}/settings/attributes`}
        />
      </div>
    </>
  );
}
