import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import { FeaturePaymentModal } from "../../../../components/modals/feature/featurePayment";
import { getOutletFromCookies } from "../../../../utils/Auth";
import { getSingleFeatureProduct } from "./../../../../redux/feature/featureActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleStoreFeature() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const outlet = getOutletFromCookies();
  const { singleFeatured } = useSelector((state) => state.feature);

  const [openPayment, setOpenPayment] = useState(false);

  useEffect(() => {
    dispatch(getSingleFeatureProduct(id));
  }, [dispatch, id]);

  const singleFood = () => {
    navigate(
      `/admin/${outlet?.restaurant_name}/food/details/${singleFeatured?.product?.id}`,
      {
        state: { food: singleFeatured?.product },
      }
    );
  };
  const singleMerchandise = () => {
    navigate(
      `/admin/${outlet?.store_name}/merchandise/${singleFeatured?.product?.id}`,
      {
        state: { merchandise: singleFeatured?.product },
      }
    );
  };

  function calcDate(date1, date2) {
    /*
     * calcDate() : Calculates the difference between two dates
     * @date1 : "First Date in the format MM-DD-YYYY"
     * @date2 : "Second Date in the format MM-DD-YYYY"
     * return : Array
     */
    //new date instance
    const dt_date1 = new Date(date1);
    const dt_date2 = new Date(date2);

    //Get the Timestamp
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    //Check which timestamp is greater
    if (date1_time_stamp > date2_time_stamp) {
      calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
      calc = new Date(date2_time_stamp - date1_time_stamp);
    }
    //Retrieve the date, month and year
    const calcFormatTmp =
      calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
    //Convert to an array and store
    const calcFormat = calcFormatTmp.split("-");
    //Subtract each member of our array from the default date
    const days_passed = Number(Math.abs(calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

    //Convert to days and sum together
    const total_days =
      years_passed * 365 + months_passed * 30.417 + days_passed;
    return Math.round(total_days);
  }

  return (
    <div>
      <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">Feature Details</h3>

          <Button.Primary
            type={"button"}
            title={"Pay For Feature"}
            disabled={singleFeatured?.featured_paid}
            onClick={() => setOpenPayment(true)}
          />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Cost</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleFeatured?.featured_amount}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
            <p className="text-[#151515] dark:text-white text-sm">{singleFeatured?.status}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Duration</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleFeatured?.featured_duration_in_hours}hrs
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Time Left</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {calcDate(
                singleFeatured?.featured_start_date,
                singleFeatured?.featured_end_date
              )}{" "}
              days
            </p>
          </div>
        </div>
      </div>
      {singleFeatured?.product?.food_name ? (
        <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium capitalize">
              {singleFeatured?.product?.food_name}
            </h3>
            <Button.Primary
              title={"View Food"}
              onClick={() => singleFood()}
              className="rounded-sm"
            />
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Food ID</label>
              <p className="text-[#151515] dark:text-white text-sm capitalize">
                {singleFeatured?.product?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Category</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFeatured?.product?.food_categories}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Stock</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {numberWithCommas(singleFeatured?.product?.stock)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
              <p className="text-[#151515] dark:text-white text-sm"> Available</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFeatured?.product?.created_at).format(
                  "DD, MMM YYYY"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {" "}
                {singleFeatured?.product?.flag}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium capitalize">
              {singleFeatured?.product?.merchandise_name}
            </h3>
            <Button.Primary
              title={"View Merchandise"}
              className="rounded-sm"
              onClick={singleMerchandise}
            />
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Merchandise ID
              </label>
              <p className="text-[#151515] dark:text-white text-sm capitalize">
                {singleFeatured?.product?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Category</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFeatured?.product?.merchandise_categories}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Stock</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {numberWithCommas(singleFeatured?.product?.stock)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
              <p className="text-[#151515] dark:text-white text-sm"> Available</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFeatured?.product?.created_at).format(
                  "DD, MMM YYYY"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFeatured?.product?.flag}
              </p>
            </div>
          </div>
        </div>
      )}

      <FeaturePaymentModal
        amount={singleFeatured?.featured_amount}
        reference_id={singleFeatured?.id}
        isOpen={openPayment}
        setOpen={setOpenPayment}
      />
    </div>
  );
}
