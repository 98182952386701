import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import Cookies from "js-cookie";
import { ReactComponent as Profile } from "../../../../assets/images/icons/profile.svg";
import { ReactComponent as Profile1 } from "../../../../assets/images/icons/profile-outline.svg";
import { ReactComponent as Outlets } from "../../../../assets/images/icons/outlet-tick.svg";
import { getUserFromCookies } from "../../../../utils/Auth";
import axios from "../../../../plugins/axios.plugin";
import { history } from "../../../../helpers/history";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleAccount({ formWizardRef }) {
  const [selected, setSelected] = useState({});
  const user = getUserFromCookies();

  const handleAccounts = (e) => {
    setSelected(e);
    axios.defaults.headers.common["Team"] = `${e}`;
    if (e === "administrator") {
      Cookies.set("team", "administrator");
      history("/admin/dashboard");
    } else if (e.includes("management")) {
      Cookies.set("team", e);
      history("/admin/dashboard");
    } else if (e === "home") {
      history("/user/home");
    } else {
      formWizardRef.current.goTo(4);
      formWizardRef.team = e;
    }
  };

  return (
    <RadioGroup value={selected} onChange={(e) => handleAccounts(e)}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="space-y-4">
        <RadioGroup.Option
          value={"home"}
          className={({ checked, active }) =>
            classNames(
              checked
                ? "border-transparent"
                : "border-gray-300 shadow-[0_3px_8px_rgba(74, 85, 104, 0.2)] shadow-md outline-0",
              active ? "border-primary ring-2 ring-primary outline-0" : "",
              "relative block bg-white dark:bg-[#010101] border rounded-lg px-4 sm:px-6 py-5 sm:py-7 cursor-pointer sm:flex sm:justify-between focus:outline-none"
            )
          }
        >
          <span className="flex gap-4 items-center">
            <Profile1 className="w-12 h-12" />
            <div className="text-sm flex flex-col">
              <RadioGroup.Label
                as="h3"
                className="font-bold text-[24px] text-[#9CA3AF] mb-1"
              >
                {user && user?.name}
              </RadioGroup.Label>
              <RadioGroup.Label
                as="span"
                className="text-[16px] text-[#9CA3AF]"
              >
                Personal Account
              </RadioGroup.Label>
            </div>
          </span>
        </RadioGroup.Option>

        {user?.team_roles &&
          Object.keys(user?.team_roles).map((role, i) => (
            <RadioGroup.Option
              key={i}
              value={role}
              className={({ checked, active }) =>
                classNames(
                  checked
                    ? "border-transparent"
                    : "border-gray-300 shadow-[0_3px_8px_rgba(74, 85, 104, 0.2)] shadow-md outline-0",
                  active ? "border-primary ring-2 ring-primary outline-0" : "",
                  "relative block bg-white dark:bg-[#121212] border dark:border-none rounded-lg px-4 sm:px-6 py-5 sm:py-7 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              <span className="flex gap-4 items-center">
                {user?.team_roles[role]?.roles?.includes("admin") ||
                user?.team_roles[role]?.roles?.includes("management") ? (
                  <Profile className="w-12 h-12" />
                ) : (
                  <Outlets className="w-12 h-12" />
                )}
                <div className="text-sm flex flex-col">
                  <RadioGroup.Label
                    as="h3"
                    className="font-bold leading-7 text-[24px] text-[#9CA3AF] mb-1"
                  >
                    {user?.team_roles[role]?.display_name}
                  </RadioGroup.Label>
                  <RadioGroup.Label
                    as="span"
                    className="text-[16px] text-[#9CA3AF]"
                  >
                    {user?.team_roles[role]?.roles?.includes("admin") ||
                    user?.team_roles[role]?.roles?.includes("management")
                      ? "Administrative Account"
                      : "Restaurants and Stores"}
                  </RadioGroup.Label>
                </div>
              </span>
            </RadioGroup.Option>
          ))}
      </div>
    </RadioGroup>
  );
}
