import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import OutletDashboardSummary from '../../components/OutletDashboardSummary';
import Card from "../../../../../components/cards/admin/cards";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import DashboardSkeleton from "../../../../../components/skeleton/dashboard";
import Empty from "../../../../../assets/images/emptyState/orders.svg";
import { ReactComponent as ORDERS } from "../../../../../assets/images/icons/orders.svg";
import { ReactComponent as MERCHANDISE } from "../../../../../assets/images/icons/merchandise.svg";
import { ReactComponent as UNSHIPPED } from "../../../../../assets/images/icons/unshipped-orders.svg";
import { ReactComponent as SHIPPED } from "../../../../../assets/images/icons/shipped-orders.svg";
import { ReactComponent as COMPLETED } from "../../../../../assets/images/icons/completed-orders.svg";
import { ReactComponent as CLOSED } from "../../../../../assets/images/icons/closed-orders.svg";
import { ReactComponent as COPY } from "../../../../../assets/images/icons/copy1.svg";
import { getOutletFromCookies } from "../../../../../utils/Auth";
import { getStoreStatistics } from "../../../../../redux/statistics/statisticsAction";
import { filterStoreOrders } from "../../../../../redux/storeOrders/storeOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function StoreStatistics() {
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  var currentDate = new Date();
  const [data, setData] = useState([]);
  const { storeStats, isLoading } = useSelector((state) => state.statistics);
  const { allStoreOrders } = useSelector((state) => state.storeOrder);

  useEffect(() => {
    if (outlet) {
      dispatch(
        getStoreStatistics({
          store_id: outlet?.id,
          start_date: moment(currentDate).format("YYYY-MM-DD"),
          end_date: moment(currentDate).format("YYYY-MM-DD"),
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(filterStoreOrders({ store_id: outlet?.id }, 1));
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.splice(0, 4).map((order, index) => {
        let show = {
          status: (
            <span>
              {order?.status === "delivered" ? (
                <span className="text-[#00D220] font-bold">
                  {order?.status}
                </span>
              ) : (
                <span className="text-primary font-bold">{order?.status}</span>
              )}
            </span>
          ),
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          payment_type: order?.order_payment_method ?? "Not available",
          id: (
            <span className="flex items-center">
              {order?.id}{" "}
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          foodData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  const summaries = [
    {
      name: "Store",
      amount: storeStats?.stats?.orders?.unshipped_orders,
      icon: UNSHIPPED,
      status: "Unshipped Orders",
    },
    {
      name: "Store",
      amount: storeStats?.stats?.orders?.shipped_orders,
      icon: SHIPPED,
      status: "Shipped Orders",
    },
    {
      name: "Store",
      amount: storeStats?.stats?.orders?.delivered_orders,
      icon: COMPLETED,
      status: "Completed Orders",
    },
    {
      name: "Store",
      amount: storeStats?.stats?.orders?.closed_orders,
      icon: CLOSED,
      status: "Closed Orders",
    },
    {
      name: "Store",
      amount: storeStats?.stats?.storeOrders?.total_orders,
      icon: ORDERS,
      status: "Total Orders",
    },
    {
      name: "Merchandise",
      amount: storeStats?.stats?.merchandise?.merchandise,
      icon: MERCHANDISE,
      status: "Total Merchandise",
    },
    {
      name: "Merchandise",
      amount: storeStats?.stats?.merchandise?.approved_merchandise,
      icon: MERCHANDISE,
      status: "Approved Merchandise",
    },
    {
      name: "Merchandise",
      amount: storeStats?.stats?.merchandise?.unapproved_merchandise,
      icon: MERCHANDISE,
      status: "Unapproved Merchandise",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Payment Type",
            accessor: "show.payment_type",
          },
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      {isLoading ? (
        <DashboardSkeleton />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6">
          {summaries?.map((summary, i) => (
            <Card.Dashboard key={i} summary={summary} />
          ))}
        </div>
      )}

      <OutletDashboardSummary />

      <div className="grid grid-cols-12 gap-6 mt-8">
        <div className="col-span-12">
          <div className="p-3 bg-white dark:bg-[#121212] rounded-md">
            <div className="border-b border-[#E4E4F3] mb-2 flex justify-between items-center">
              <h3 className="text-lg py-2 font-medium">Recent Orders</h3>
              {data?.length > 0 && (
                <a
                  href={`/admin/${outlet?.store_name}/orders/shipped/1`}
                  className="text-primary"
                >
                  See all
                </a>
              )}
            </div>

            {data?.length > 0 ? (
              <>
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    columns={columns}
                    data={data?.length > 0 ? data : []}
                    className="w-full"
                    onClick={() => {}}
                  />
                )}
              </>
            ) : (
              <div className="text-center">
                {" "}
                <div className="flex justify-center my-12 ">
                  <img src={Empty} alt="orders" />
                </div>
                <p className="font-bold text-xl my-3">Store Orders</p>
                <p className="text-sm text-gray-500">
                  You do not have any store Orders yet
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
