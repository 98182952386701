import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import COPY from "../../../assets/images/icons/copy.svg";
import BACKGROUND from "../../../assets/images/pictures/share-link-bg.jpg";
// import { MODMAGE } from "../../../assets/images/pictures/images";
import {
  FACEBOOK,
  SKYPE,
  WHATSAPP,
  MESSAGE,
  MESSENGER,
  INSTAGRAM,
  TELEGRAM,
  TWITTER,
} from "../../../assets/images/icons/icon";
import Button from "../../buttons/buttons";
import { copyTextToClipboard } from "../../helpers";
import { getOutletFromCookies } from "../../../utils/Auth";
import { getOutletTypeFromCookies } from "../../../utils/Auth";

export default React.forwardRef(function ShareLinkModal(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  let [isOpen, setIsOpen] = React.useState(visibility);
  let base_url = window.location.origin;
  const outlet = getOutletFromCookies();
  const outlet_type = getOutletTypeFromCookies();

  const text =
    outlet_type === "restaurant"
      ? `${base_url}/restaurant-checkout/${outlet?.id}`
      : `${base_url}/store-checkout/${outlet?.id}`;

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function shareOnWhatsApp() {
    const message = encodeURIComponent(
      `Hey! view my store directly through this link:\n ${text}`
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  }

  function shareOnFacebook() {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${text}`;
    window.open(facebookUrl, "_blank");
  }

  function shareOnInstagram() {
    const instagramUrl = `https://www.instagram.com/?url=${text}`;
    window.open(instagramUrl, "_blank");
  }

  function shareOnTwitter() {
    const message = encodeURIComponent(
      `Hey! view my store directly through this link:\n ${text}`
    );
    const twitterUrl = `https://twitter.com/intent/tweet?url=${text}&text=${message}`;
    window.open(twitterUrl, "_blank");
  }

  function shareOnTelegram() {
    const message = encodeURIComponent(
      `Hey! view my store directly through this link:\n ${text}`
    );
    const telegramUrl = `https://t.me/share/url?url=${text}&text=${message}`;
    window.open(telegramUrl, "_blank");
  }

  function shareOnSkype() {
    const skypeUrl = `https://web.skype.com/share?url=${text}`;
    window.open(skypeUrl, "_blank");
  }

  function shareOnMessenger() {
    const url = text;
    const fbUrl = `fb-messenger://share/?link=${encodeURIComponent(url)}`;
    window.open(fbUrl, "_blank");
  }

  function shareViaText() {
    const message = encodeURIComponent(
      `Hey! view my store directly through this link:\n ${text}`
    );
    const smsUrl = `sms:?body=${message}`;
    window.open(smsUrl, "_blank");
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[600px] bg-white dark:bg-[#121212] rounded-3xl  pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full  ">
                  <img src={BACKGROUND} alt="Food" className="w-full" />
                  <div className=" sm:block absolute top-3 right-5 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] rounded-md p-1 sm:p-3 "
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="px-4 sm:p-8">
                    <h3 className=" text-base sm:text-3xl font-medium text-left mb-4 mt-4 sm:mb-6">
                      Hey! Share this link with your customers.
                    </h3>
                    <div className=" border-b "></div>
                    <div className="bg-[#FFEAE980] dark:bg-[#01010180] rounded-md mt-5 p-4 flex justify-between">
                      <p className="text-left text-base leading-5 max-w-[211px] break-words sm:max-w-[340px]">
                        {text}
                      </p>
                      <div className="border-r-2 border-gray-400 sm:h-8 h-11 mt-3 sm:mt-1"></div>
                      <span>
                        <img
                          className="mt-3 sm:mt-0 cursor-pointer"
                          src={COPY}
                          alt="copy icon"
                          onClick={() => {
                            copyTextToClipboard(text);
                          }}
                        />
                      </span>
                    </div>
                    <div className=" border-b mt-8 mb-6 "></div>
                    <div className="grid grid-cols-4 gap-2">
                      <button onClick={() => shareOnWhatsApp()}>
                        <img src={WHATSAPP} alt="icon" />
                        <p className=" text-base text-center mr-5">Whatsapp</p>
                      </button>
                      <button onClick={() => shareOnFacebook()}>
                        <img src={FACEBOOK} alt="icon" />
                        <p className=" text-base text-center mr-5">Facebook</p>
                      </button>
                      <button onClick={() => shareOnMessenger()}>
                        <img src={MESSENGER} alt="icon" />
                        <p className=" text-base text-center mr-5">Messenger</p>
                      </button>
                      <button onClick={() => shareViaText()}>
                        <img src={MESSAGE} alt="icon" />
                        <p className=" text-base text-center mr-5">Message</p>
                      </button>
                      <button onClick={() => shareOnInstagram()}>
                        <img src={INSTAGRAM} alt="icon" />
                        <p className=" text-base text-center mr-5">Instagram</p>
                      </button>
                      <button onClick={() => shareOnSkype()}>
                        <img src={SKYPE} alt="icon" />
                        <p className=" text-base text-center mr-5">Skype</p>
                      </button>
                      <button onClick={() => shareOnTwitter()}>
                        <img src={TWITTER} alt="icon" />
                        <p className=" text-base text-center mr-5">Twitter</p>
                      </button>
                      <button onClick={() => shareOnTelegram()}>
                        <img src={TELEGRAM} alt="icon" className="mt-2 ml-2" />
                        <p className=" text-base text-center mr-5 mt-4">
                          Telegram
                        </p>
                      </button>
                    </div>
                    <Button.Primary
                      title={"Cancel"}
                      onClick={closeModal}
                      className=" h-[60px] sm:w-[450px] w-80 mt-16 mb-10 sm:mb-24"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
