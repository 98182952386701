import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveFeaturedProducts from "./active";
import { getOutletFromCookies } from "../../../../utils/Auth";
import InactiveFeaturedProducts from "./inactive";

const tabs = [
  { name: "Active", href: "active" },
  { name: "Inactive", href: "inactive" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StoreFeature() {
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const DEFAULT_ACTIVE_TAB = "active";
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(
        `/admin/${
          outlet?.store_name || outlet?.restaurant_name
        }/features/${DEFAULT_ACTIVE_TAB}/1`
      );
    }
  });

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(
        `/admin/${outlet?.store_name || outlet?.restaurant_name}/features/${
          tab.href
        }/1`
      );
    }
  };

  return (
    <div className="business-page">
      <div className="sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-scroll sm:overflow-hidden"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 dark:text-[#BCBCBC] hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {active_tab === "active" && <ActiveFeaturedProducts />}
        {active_tab === "inactive" && <InactiveFeaturedProducts />}
      </div>
    </div>
  );
}
