import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../../../../components/buttons/buttons";
import Card from "../../../../../../components/cards/admin/cards";
import Table from "../../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../../components/skeleton/table";
import EmptyState from "../../../../../../assets/images/emptyState/orders.svg";
import { Beat } from "../../../../../../plugins/spinners.plugin";
import { Pagination } from "../../../../../../components/pagination/pagination";
import { ReactComponent as COPY } from "../../../../../../assets/images/icons/copy1.svg";
import { writeFileWithXLSX } from "../../../../../../components/exports/xlsx";
import { copyTextFromTable } from "../../../../../../components/helpers";
import { AllFiltersModal } from "../../../../../../components/modals/allFiltersModal";
import FilterRestaurantOrdersDropDown from "../../../../../../components/dropDown/filter/filterRestaurantOrders";
import {
  filterRestaurantOrderAction,
  searchRestaurantOrderAction,
  getAllRestaurantOrdersNoPagination,
} from "../../../../../../redux/restaurantOrder/restaurantOrderActions";
import restaurantType from "../../../../../../redux/restaurantOrder/restaurantOrderTypes";
import { getOutletFromCookies } from "../../../../../../utils/Auth";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Shipped() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const [payLoad] = useState({
    status: "shipped",
    end_date: new Date(),
    restaurant_id: outlet?.id,
  });

  const { allOrders, downloading, filtering, searching } = useSelector(
    (state) => state.restaurantOrder
  );

  useEffect(() => {
    dispatch(filterRestaurantOrderAction(payLoad, page));
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          id: (
            <span className="flex items-center">
              #
              {order?.id?.substr(0, 8) +
                "...." +
                order?.id?.substr(order?.id?.length - 8)}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          status: (
            <span>
              {order?.order_paid ? (
                <span className="text-[#00D220] font-bold">Paid</span>
              ) : (
                <span className="text-primary font-bold">Unpaid</span>
              )}
            </span>
          ),
          delivery_fee: (
            <span>NGN {numberWithCommas(order?.order_delivery_fee)}</span>
          ),
          order_status: (
            <span className="text-[#046ECF] font-bold">{order?.status}</span>
          ),
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          id: order?.id,
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: order?.order_amount,
          status: order?.order_paid ? "Paid" : "Unpaid",
          delivery_fee: order?.order_delivery_fee,
          order_status: order?.status,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders]);

  function goToSinglePage(params) {
    let id = params?.show?.orderData?.id;
    navigate(`/admin/${outlet?.restaurant_name}/order/restaurant/${id}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allOrders?.data);
      setData(dataSet);
      dispatch({
        type: restaurantType["SEARCH_RESTAURANT_ORDERS"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchRestaurantOrderAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  // filterRestaurantOrderAction;
  const handleDownload = async () => {
    const response = await dispatch(
      getAllRestaurantOrdersNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Shipped_Orders.xlsx",
        download: true,
      });
    }
  };

  function goToCreate() {
    navigate(`/admin/${outlet?.restaurant_name}/order/restaurant/create-order`);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Delivery Fee",
            accessor: "show.delivery_fee",
          },
          {
            Header: "Payment Status",
            accessor: "show.status",
          },
          {
            Header: "Orders Status",
            accessor: "show.order_status",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="orders-page">
      <Card.TableFilter
        title={"Orders"}
        results={allOrders?.to}
        totalResults={allOrders?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Order ID or Phone Number"}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterRestaurantOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary title={"+ Create Order"} onClick={goToCreate} />

        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          onClick={handleDownload}
          disabled={!data}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <>
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              onClick={goToSinglePage}
              className="w-full"
              rowClass="hover:shadow-md cursor-pointer"
            />
            <Pagination
              data={allOrders}
              route={`admin/${outlet?.restaurant_name}/orders/shipped`}
            />
          </>
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Shipped Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any shipped orders yet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
