import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyBusiness } from "../../../../../redux/business/businessActions";
import Button from "../../../../../components/buttons/buttons";
import { useNavigate } from "react-router-dom";
import { getOutletFromCookies } from "../../../../../utils/Auth";
import BusinessTable from ".";

export default function MyBusiness() {
  const outlet = getOutletFromCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myBusiness } = useSelector((state) => state.business);

  function goToEditPage() {
    navigate(
      `/admin/${
        outlet?.store_name || outlet?.restaurant_name
      }/settings/business/edit`,
      {
        state: { business: myBusiness },
      }
    );
  }

  useEffect(() => {
    dispatch(getMyBusiness());
  }, [dispatch]);

  return (
    <div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">
            {myBusiness?.business_name}
          </h3>
          <Button.Primary title={"Edit Business"} onClick={goToEditPage} />
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Name
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {myBusiness?.business_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Email
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {myBusiness?.business_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Phone Number
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {myBusiness?.business_phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Address
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {myBusiness?.business_address}
            </p>
          </div>
        </div>
      </div>
      <BusinessTable myBusiness={myBusiness} />
    </div>
  );
}
