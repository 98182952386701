import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyBusinessSettings } from "../../../../redux/businessSettings/businessSettingsActions";

export default function OutletDashboardSummary() {
  const dispatch = useDispatch();
  const { myBusinessSettings } = useSelector((state) => state.businessSettings);

  useEffect(() => {
    dispatch(getMyBusinessSettings());
  }, [dispatch]);

  return (
    <div className="grid grid-col-1 md:grid-cols-2 gap-4 mt-12">
      <div className="px-3 py-4 sm:px-8 sm:py-8 border dark:border-none bg-white dark:bg-[#121212] rounded-md">
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-[#0148FF] font-bold">
            Currency
          </span>
          <span className="text-[#344B67] dark:text-white font-bold capitalize">
            {myBusinessSettings?.currency}
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-[#0148FF] font-bold">
            Insurance Percentage
          </span>
          <span className="text-[#344B67] dark:text-white font-bold capitalize">
            {myBusinessSettings?.insurance_percentage}%
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-[#0148FF] font-bold">Tax Percentage</span>
          <span className="text-[#344B67] dark:text-white font-bold capitalize">
            {myBusinessSettings?.tax_percentage}%
          </span>
        </div>
      </div>

      <div className="px-3 py-4 sm:px-8 sm:py-8 border dark:border-none bg-white dark:bg-[#121212] rounded-md">
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-[#0148FF] font-bold">VAT Percentage</span>
          <span className="text-[#344B67] dark:text-white font-bold capitalize">
            {myBusinessSettings?.vat_percentage}%
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-[#0148FF] font-bold">Discount Percentage</span>
          <span className="text-[#344B67] dark:text-white font-bold capitalize">
            {myBusinessSettings?.discount_percentage}%
          </span>
        </div>
        {/* <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] font-bold">Discount Amount</span>
          <span className="text-[#344B67] font-bold capitalize">
            {myBusinessSettings?.discount_amount}
          </span>
        </div> */}
      </div>
    </div>
  );
}
