import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import Cookies from "js-cookie";
import Button from "../../../buttons/buttons";
import { ReactComponent as Store } from "../../../../assets/images/icons/outlet.svg";
import EmptyState from "../../../../assets/images/emptyState/store.svg";
// import { getTeamFromCookies } from "../../../../utils/Auth";
import { history } from "../../../../helpers/history";
import { filterStores } from "../../../../redux/stores/storeActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ChooseStore({ formWizardRef, team }) {
  // const team = getTeamFromCookies();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});
  const { allStores } = useSelector((state) => state.store);

  useEffect(() => {
    if (team) {
      dispatch(filterStores({ business_id: team }));
    }
    // eslint-disable-next-line
  }, [team]);

  const handleAccounts = (e) => {
    setSelected(e);
    Cookies.set("team", team);
    Cookies.set("outlet", JSON.stringify(e));
    Cookies.set("outlet-type", "store");
    history(`/admin/${e?.store_name}/dashboard`);
  };

  return (
    <RadioGroup value={selected} onChange={(e) => handleAccounts(e)}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="space-y-4">
        {allStores?.data?.length > 0 ? (
          allStores?.data.map((store, i) => (
            <RadioGroup.Option
              key={i}
              value={store}
              className={({ checked, active }) =>
                classNames(
                  checked
                    ? "border-transparent"
                    : "border-gray-300 shadow-[0_3px_8px_rgba(74, 85, 104, 0.2)] shadow-md outline-0",
                  active ? "border-primary ring-2 ring-primary outline-0" : "",
                  "relative block bg-white dark:bg-[#010101] border rounded-lg px-4 sm:px-6 py-5 sm:py-7 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex gap-4 items-center w-full">
                    <Store className="w-12 h-12" />
                    <div className="text-sm w-full">
                      <div className="flex justify-between items-center gap-2">
                        <RadioGroup.Label
                          as="span"
                          className="font-medium text-2xl"
                        >
                          {store?.store_name}
                        </RadioGroup.Label>
                        <div className="bg-[#FBEDC5] text-sm font-medium text-[#333333] px-2">
                          {store?.id}
                        </div>
                      </div>

                      <RadioGroup.Description
                        as="span"
                        className="text-gray-500 block sm:inline font-md text-lg"
                      >
                        <span className="block sm:inline">
                          {store?.store_address}
                        </span>
                      </RadioGroup.Description>
                    </div>
                  </span>

                  <span
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-primary" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))
        ) : (
          <div className="py-28 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Stores</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any store yet.
            </p>
          </div>
        )}

        <div
          className={`${
            allStores?.data?.length > 4 ? "relative mt-4" : "absolute bottom-1"
          } w-full p-4 sm:p-4 left-0 right-0 text-center`}
        >
          <Button.Primary
            title={"Create New Store"}
            onClick={() => {
              formWizardRef.current.goTo(6);
            }}
            className="w-full py-4 sm:w-[334px] h-[60px]"
          />
        </div>
      </div>
    </RadioGroup>
  );
}
