import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import Location from "../../../components/includes/location";
// images
import { EMPTY } from "../../../assets/images/pictures/images";
import { Beat } from "../../../plugins/spinners.plugin";
import { cleanInput } from "../../../helpers/cleanInput";
import {
  editAProfileAction,
  getMyProfileAction,
} from "../../../redux/profile/profileActions";
import { editAUserAction } from "../../../redux/users/userActions";

export default function Personal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileData, setprofileData] = useState({});
  const [data, setData] = useState({});
  const [images, setImages] = useState({ preview: EMPTY, base64: "" });

  const { myProfile } = useSelector((state) => state.profile);
  const { updating } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getMyProfileAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onImageError = (e) => {
    e.target.src = EMPTY;
  };
  useEffect(() => {
    setprofileData(myProfile);
  }, [myProfile]);

  useEffect(() => {
    setData({
      user_id: profileData?.user_id,
      first_name: profileData?.first_name ?? "",
      address: profileData?.address,
      phone: profileData?.phone,
      last_name: profileData?.last_name ?? "",
      gender: profileData?.gender,
      country: profileData?.country,
      state: profileData?.state,
      city: profileData?.city,
    });
  }, [profileData]);

  const onImageChange = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => {
        setImages({
          preview: reader.result,
          base64: reader.result,
        });
      };
    }
  };

  useEffect(() => {
    if (myProfile?.picture) {
      let dataObject = {
        preview: myProfile?.picture,
      };
      setImages({ ...dataObject });
    }
    // Update the document title using the browser API
  }, [myProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };
  const handleUserPhone = (e) => {
    let user_phone = e?.slice(1);
    setData({ ...data, phone: user_phone });
  };

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = () => {
    if (images?.base64) {
      let newInput = {
        ...data,
        base64_photos: [images?.base64] ? [images?.base64] : [],
      };
      cleanInput(newInput);
      dispatch(editAProfileAction(newInput));
      dispatch(editAUserAction(newInput));
      return;
    }
    let newInput = {
      ...data,
    };
    cleanInput(newInput);
    dispatch(editAProfileAction(newInput));
    dispatch(editAUserAction(newInput));
  };

  return (
    <div>
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 bg-white dark:bg-[#121212] sm:h-[1000px] rounded-sm shadow-2xl">
        <div className="border-b-2 py-4 sm:py-6 mb-6 flex sm:block justify-between items-center">
          <p className="text-base font-medium text-gray-800 dark:text-white text-center">
            Personal Info
          </p>
        </div>
        <form className="sm:px-10">
          <label
            htmlFor="upload-button"
            className="flex justify-center mx-auto mb-6 cursor-pointer"
          >
            <div>
              <figure className="w-full h-[160px] relative">
                <img
                  src={images.preview ?? EMPTY}
                  onError={onImageError}
                  className="w-[160px] h-[160px]"
                  alt="profile"
                />
              </figure>

              <p className="font-bold py-4 text-center">Upload Photo</p>
            </div>
          </label>
          <input
            type="file"
            id="upload-button"
            className="hidden"
            accept="image/*"
            onChange={onImageChange}
          />{" "}
          <Location
            country_id={locationId.country_id}
            state_id={locationId.state_id}
            city_id={locationId.city_id}
            countryList={(value) => {
              setLocationList({
                ...locationList,
                countryList: value,
              });
            }}
            stateList={(value) => {
              setLocationList({
                ...locationList,
                stateList: value,
              });
            }}
            cityList={(value) => {
              setLocationList({ ...locationList, cityList: value });
            }}
          />
          <div className="sm:grid sm:grid-cols-2 gap-6">
            {/* grid column one */}
            <div>
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                    placeholder="Enter first name"
                    defaultValue={data?.first_name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="mt-4 sm:mt-0">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium"
                >
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                    placeholder="Enter last name"
                    value={data?.last_name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="mt-4 sm:mt-0">
                <Input.Select
                  title={"Gender"}
                  name="gender"
                  id="gender"
                  className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                  htmlFor={"gender"}
                  value={data.gender}
                  onChange={(e) => handleChange(e)}
                >
                  <option>{"Select Gender"}</option>
                  <option className=" text-base" value={"male"}>
                    Male
                  </option>
                  <option className=" text-base" value={"female"}>
                    Female
                  </option>
                  <option className=" text-base" value={"unknown"}>
                    Unknown
                  </option>
                </Input.Select>
              </div>
              <div className="mt-4 sm:mt-0">
                <Input.Phone
                  title={"Phone Number"}
                  name="phone"
                  htmlFor="phone"
                  value={data?.phone}
                  onChange={(e) => handleUserPhone(e)}
                />
              </div>
            </div>
            {/* grid column two */}
            <div>
              <div>
                <label htmlFor="address" className="block text-sm font-medium">
                  Address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                    placeholder="Enter Address"
                    defaultValue={data?.address}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="mt-4 sm:mt-0">
                <Input.Select
                  title={"Select Country"}
                  name="country"
                  id="country"
                  className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                  htmlFor="country"
                  value={data?.country}
                  onChange={(e) => {
                    setLocationId({
                      ...locationId,
                      country_id: fromJson(e.target.value).id,
                    });
                    handleRegion(e);
                  }}
                >
                  <option>{data?.country ?? "Select Country"}</option>
                  {locationList.countryList &&
                    locationList.countryList.length > 0 &&
                    locationList.countryList.map((item, key) => {
                      return (
                        <option
                          key={key}
                          value={toJson({
                            name: item.name,
                            id: item.id,
                          })}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Input.Select>
              </div>
              <div className="mt-4 sm:mt-0">
                <Input.Select
                  title={"Select State"}
                  name="state"
                  id="state"
                  className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                  htmlFor={"state"}
                  value={data?.state}
                  onChange={(e) => {
                    setLocationId({
                      ...locationId,
                      state_id: fromJson(e.target.value).id,
                    });
                    handleRegion(e);
                  }}
                >
                  <option>{data?.state ?? "Select State"}</option>
                  {locationList.stateList &&
                    locationList.stateList.length > 0 &&
                    locationList.stateList.map((item, key) => {
                      return (
                        <option
                          key={key}
                          value={toJson({
                            name: item.name,
                            id: item.id,
                          })}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Input.Select>
              </div>
              <div>
                <Input.Select
                  title={"Select City"}
                  name="city"
                  id="city"
                  value={data?.city}
                  className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                  htmlFor={"city"}
                  onChange={(e) => {
                    setLocationId({
                      ...locationId,
                      city_id: fromJson(e.target.value).id,
                    });
                    handleRegion(e);
                  }}
                >
                  <option>{data?.city ?? "Select City"}</option>
                  {locationList.cityList &&
                    locationList.cityList.length > 0 &&
                    locationList.cityList.map((item, key) => {
                      return (
                        <option
                          key={key}
                          value={toJson({
                            name: item.name,
                            id: item.id,
                          })}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Input.Select>
              </div>
            </div>
          </div>
          {/* grid ends */}
          <div className="my-10 flex flex-col items-center justify-center">
            <Button.Primary
              type="button"
              disabled={updating}
              title={updating ? <Beat color="#ffffff" /> : "Save Changes"}
              className={"sm:block rounded-md mb-6 sm:mb-0 w-full sm:w-[333px]"}
              onClick={(e) => handleSubmit(e)}
            />
            <Button.Secondary
              type="button"
              title={"Back"}
              className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full"}
              onClick={() => navigate("/profile")}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
