import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import CLOSE from "../../../assets/images/icons/close.svg";
import PaymentSkeleton from "../../skeleton/payment";
import { getASubscriptionFoodOrder } from "../../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default React.forwardRef(function ViewFoodPackageOrder(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getId: (data) => getOrderId(data),
  }));

  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = React.useState(visibility);
  let [orderId, setOrderId] = React.useState();
  const { isLoading, singlePackageOrder } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );

  useEffect(() => {
    if (orderId) {
      dispatch(getASubscriptionFoodOrder(orderId));
    }
    // eslint-disable-next-line
  }, [dispatch, orderId]);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function getOrderId(data) {
    setOrderId(data);
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[629px] bg-white dark:bg-[#121212] rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full sm:p-8">
                    <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                      <button type="button" className="" onClick={closeModal}>
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-meduim text-black dark:text-white"
                      >
                        Order Details
                      </Dialog.Title>
                    </div>

                    <div className="mt-3 sm:mt-2">
                      <div className="border-b-2"></div>
                      {isLoading ? (
                        <PaymentSkeleton />
                      ) : (
                        <>
                          {singlePackageOrder?.order_items?.length > 0 &&
                            singlePackageOrder?.order_items?.map(
                              (product, i) => (
                                <div key={i}>
                                  <div className="flex justify-between items-start my-5">
                                    <div className="flex">
                                      <span className="w-6 mr-10">
                                        {product.quantity}x
                                      </span>
                                      <div className="text-left">
                                        <p className="mb-2">
                                          {product?.product_name}
                                        </p>
                                        {product?.product_attributes?.length >
                                          0 &&
                                          product?.product_attributes?.map(
                                            (attr, i) => (
                                              <p key={i} className="text-sm">
                                                {attr.quantity}x {attr?.name} -
                                                ₦{" "}
                                                {numberWithCommas(attr.amount)}
                                              </p>
                                            )
                                          )}
                                      </div>
                                    </div>
                                    <div className="flex gap-4">
                                      <p>
                                        ₦{" "}
                                        {numberWithCommas(
                                          product?.product_amount
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                        </>
                      )}
                    </div>

                    <div className="mt-3 sm:mt-2 shadow-lg px-4 rounded-md">
                      <div className="border-b-2 py-2 border-[#EAEAEA]">
                        <h3 className="text-left font-bold text-lg">
                          Payment Summary
                        </h3>
                      </div>

                      <div className="flex justify-between items-center py-2">
                        <span>Amount</span>
                        <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                          ₦
                          {numberWithCommas(
                            singlePackageOrder?.order_sub_amount
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between items-center py-2">
                        <span>Delivery Fee</span>
                        <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                          ₦
                          {numberWithCommas(
                            singlePackageOrder?.order_delivery_fee
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between items-center py-2">
                        <span>Service Fee</span>
                        <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                          ₦
                          {numberWithCommas(
                            singlePackageOrder?.order_service_fee
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between items-center py-2">
                        <span>Insurance Fee</span>
                        <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                          ₦
                          {numberWithCommas(
                            singlePackageOrder?.order_insurance_fee
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between items-center py-2">
                        <span>Discount</span>
                        <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                          ₦
                          {numberWithCommas(singlePackageOrder?.order_discount)}
                        </span>
                      </div>
                      <div className="flex justify-between items-center py-2 border-t-2 border-[#EAEAEA]">
                        <span>Total</span>
                        <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                          ₦{numberWithCommas(singlePackageOrder?.order_amount)}
                        </span>
                      </div>
                    </div>

                    {singlePackageOrder &&
                      singlePackageOrder?.order_coupons?.length > 0 && (
                        <div className="mt-4 sm:mt-4 shadow-lg px-4 rounded-md">
                          <div className="border-b-2 py-2 border-[#EAEAEA]">
                            <h3 className="text-left font-medium text-lg">
                              Coupon Applied
                            </h3>
                          </div>

                          <div className="flex justify-between items-center py-2">
                            <span>Code</span>
                            <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                              {singlePackageOrder?.order_coupons?.[0]?.slug}
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2">
                            <span>Amount</span>
                            <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                              ₦
                              {numberWithCommas(
                                singlePackageOrder?.order_coupons?.[0]?.amount
                              )}
                            </span>
                          </div>
                        </div>
                      )}

                    {singlePackageOrder &&
                      singlePackageOrder?.order_promotion_codes?.length > 0 && (
                        <div className="mt-4 sm:mt-4 shadow-lg px-4 rounded-md">
                          <div className="border-b-2 py-2 border-[#EAEAEA]">
                            <h3 className="text-left font-medium text-lg">
                              Promo Code Applied
                            </h3>
                          </div>

                          <div className="flex justify-between items-center py-2">
                            <span>Code</span>
                            <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                              {
                                singlePackageOrder?.order_promotion_codes?.[0]
                                  ?.slug
                              }
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2">
                            <span>Amount</span>
                            <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                              ₦
                              {numberWithCommas(
                                singlePackageOrder?.order_promotion_codes?.[0]
                                  ?.amount
                              )}
                            </span>
                          </div>
                        </div>
                      )}

                    <Button.Primary
                      title={"Back"}
                      type="button"
                      className="w-full py-3 mt-6"
                      onClick={closeModal}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
