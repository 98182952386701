import React, { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
// components
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import { Beat } from "../../../../plugins/spinners.plugin";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/orders.svg";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
// requests
import { getOutletFromCookies } from "../../../../utils/Auth";
import { getAUserAction } from "../../../../redux/users/userActions";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { getRestaurantStatistics } from "../../../../redux/statistics/statisticsAction";
import {
  filterRestaurantOrderAction,
  searchRestaurantOrderAction,
  getAllRestaurantOrdersNoPagination,
} from "../../../../redux/restaurantOrder/restaurantOrderActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function OutletRestaurantReportDetail() {
  const outlet = getOutletFromCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
  });

  const { allOrders, filtering, downloading, search } = useSelector(
    (state) => state.restaurantOrder
  );
  const { singleUser } = useSelector((state) => state.user);
  const { restaurantStats } = useSelector((state) => state.statistics);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (outlet?.id) {
      dispatch(getAUserAction(outlet?.user_id));
      dispatch(
        getRestaurantStatistics({ ...payLoad, restaurant_id: outlet?.id })
      );
      dispatch(
        filterRestaurantOrderAction(
          { ...payLoad, restaurant_id: outlet?.id },
          1
        )
      );
    }
    // eslint-disable-next-line
  }, [outlet?.id]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.splice(0, 4).map((order) => {
        let show = {
          id: (
            <span className="flex items-center">
              {order?.id}{" "}
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          createdAt: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          payment_type: order?.order_payment_method ?? "Not available",
          status: order?.status,
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.splice(0, 4).map((order) => {
        let show = {
          id: order?.id,
          createdAt: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: numberWithCommas(order?.order_amount),
          payment_type: order?.order_payment_method ?? "Not available",
          status: order?.status,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }
  useLayoutEffect(() => {
    const dataSet = createTableData(allOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllRestaurantOrdersNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Restaurant_Orders.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allOrders);
      setData(dataSet);
    }
  };
  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchRestaurantOrderAction(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order Status",
            accessor: "show.status",
          },
          {
            Header: "Date",
            accessor: "show.createdAt",
          },
          {
            Header: "Payment Type",
            accessor: "show.payment_type",
          },
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
        ],
      },
    ],
    []
  );

  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">{outlet?.restaurant_name}</h3>
          <Button.Primary
            title={"Check Stats"}
            onClick={() =>
              navigate(`/admin/${outlet?.restaurant_name}/report/statistics`)
            }
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Restaurant ID
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.id}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Email
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.restaurant_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Phone Number
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.restaurant_phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Flag Count
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.flag}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Status
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Created On
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(outlet?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Country
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.restaurant_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              State
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.restaurant_state}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              City
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {outlet?.restaurant_city}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Restaurant Owner Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Name
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Email
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Phone Number
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Address
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.address?.[0]?.name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Approve/Unapprove
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.name}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders History</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Total Orders{" "}
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.total_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Delivered Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.delivered_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Shipped Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.shipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Unshipped Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.unshipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Standard Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.standard_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Closed Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.closed_orders}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Card.TableFilter
          onChange={handleChange}
          searchPlaceholder={"Search Orders"}
        >
          <Button.Secondary
            title={downloading ? <Beat color={"#ce2600"} /> : "Download CSV"}
            disabled={!data}
            onClick={handleDownload}
          />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3]"></div>

        <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
          {/* <h4>Orders Summary</h4> */}
          {filtering ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={() => {}}
            />
          ) : (
            <div className="py-16 text-center">
              <div className="flex justify-center">
                <img src={EmptyState} className="w-auto" alt="no data" />
              </div>
              <h3 className="mt-5 text-xl font-bold">Orders</h3>
              <p className="mt-2 text-sm text-[#9CA3AF] dark:text-[#BCBCBC]">
                You do not have any orders yet.
              </p>
            </div>
          )}
        </div>
        <Pagination />
      </div>
    </div>
  );
}
