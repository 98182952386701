import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../buttons/buttons";
import Input from "../../../inputs/inputs";
import { Beat } from "../../../../plugins/spinners.plugin";
import {
  updateAFoodAction,
  getAFoodAction,
} from "../../../../redux/food/foodActions";

export default function EditFoodPriceModal(props) {
  const { price, setPrice } = props;
  const dispatch = useDispatch();
  const { updating } = useSelector((state) => state.food);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrice({ ...price, [name]: value });
  };
  const handleUpdateData = async () => {
    try {
      const response = await dispatch(updateAFoodAction(price));
      if (response) {
        props.onClose();
        dispatch(getAFoodAction(price.id));
      }
    } catch (error) {}
  };

  return (
    <div className="Coupon" data-test="couponModal">
      <Transition appear show={props.show}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-white dark:bg-[#121212] w-[380px] text-left sm:w-[400px] p-4 sm:p-6">
                  <div className="pb-5 text-center sm:mt-2 sm:text-center border-b border-[#E4E4F3]">
                    <Dialog.Title as="h3" className="text-lg text-black dark:text-white">
                      Edit Food Price
                    </Dialog.Title>
                  </div>

                  <>
                    <div className="mx-auto py-8">
                      <form className="flex gap-4">
                        <Input.Number
                          title={"Amount"}
                          htmlFor={"food_amount"}
                          name={"food_amount"}
                          onChange={handleChange}
                          value={price?.food_amount}
                          placeholder={"Enter Price"}
                        />

                        <Input.Number
                          title={"Discount"}
                          htmlFor={"food_discount"}
                          name={"food_discount"}
                          onChange={handleChange}
                          value={price?.food_discount}
                          placeholder={"Enter Discount"}
                        />
                      </form>
                    </div>
                    <div className="flex justify-between gap-8">
                      <Button.Secondary
                        title={"Cancel"}
                        className="w-full"
                        onClick={props.onClose}
                      />
                      <Button.Primary
                        title={
                          updating ? <Beat color={"#ffffff"} /> : "Proceed"
                        }
                        type={"button"}
                        className="w-full"
                        onClick={handleUpdateData}
                      />
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
