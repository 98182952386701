import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// Navigation
import NavBarCommon from "../components/navigation/NavBarCommon";
import FooterCommon from "../components/footer/FooterCommon";
import PrivateRoute from "../utils/privateRoute";
// Pages
import LandingPage from "../pages/common/Landing";
// import Restaurant from "../pages/user/restaurant/Restaurant";
import Home from "../pages/user/home";
// import Cart from "../pages/user/Cart";
import Cart from "../pages/user/cart/index";
import CartGuestCheckout from "../pages/user/cart/GuestCheckout";
import FoodPackage from "../pages/user/restaurant/foodPackage";
import CustomisePlan from "../pages/user/restaurant/foodPackage/CustomisePlan";
import ChooseMeals from "../pages/user/restaurant/foodPackage/ChooseMeals";
import ConfirmMeals from "../pages/user/restaurant/foodPackage/ConfirmMeals";
import Help from "../pages/user/help";
import Search from "../pages/user/search";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import OnboardBusiness from "../pages/user/Business/onboardBusiness";
import CreateRestaurant from "../pages/admin/restaurant/createRestaurant";
import CreateStore from "../pages/admin/stores/createStore";
import CreateStoreBusiness from "../pages/user/Business/createStoreBusiness";
import InviteReg from "../pages/user/profile/invite/inviteLinkReg";
import ResetPassword from "../pages/common/ResetPassword";
import Error404 from "../pages/common/Error404";
// import Store from "./../pages/user/stores/store";
import { BankTransfer } from "./../pages/user/Payment";
import Success from "../pages/user/Business/successScreen";
import Profile from "../pages/user/profile";
import ProfileMobile from "../pages/user/profile/indexMobile";
import PayForMe from "../pages/user/PayForMe";
import LazyLoader from "../components/LazyLoader";

export default function UserLayout() {
  const SingleRestaurant = lazy(() =>
    import("../pages/user/restaurant/Restaurant")
  );
  const SingleStore = lazy(() => import("./../pages/user/stores/store"));
  const StoreCheckout = lazy(() =>
    import("../pages/user/guestCheckout/StoreCheckout")
  );
  const RestaurantCheckout = lazy(() =>
    import("../pages/user/guestCheckout/RestaurantCheckout")
  );
  const GuestCheckoutDetails = lazy(() =>
    import("../pages/user/guestCheckout/StoreCheckoutDetails")
  );
  const RestaurantCheckoutDetails = lazy(() =>
    import("../pages/user/guestCheckout/RestaurantCheckoutDetails")
  );
  return (
    <React.Fragment>
      <main className="relative bg-white dark:bg-[#010101] min-h-screen">
        <NavBarCommon />
        <section>
          {/* Body */}
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route
              path="/api/auth/socialite/google/callback"
              element={<Home />}
            />
            <Route
              path="/api/auth/socialite/apple/callback"
              element={<Home />}
            />
            {/* <Route path="/restaurant/:id" exact element={<Restaurant />} /> */}
            <Route
              path="/restaurant/:id"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <SingleRestaurant />
                </Suspense>
              }
            />
            {/* <Route path="/store/:id" exact element={<Store />} /> */}
            <Route
              path="/store/:id"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <SingleStore />
                </Suspense>
              }
            />
            <Route
              path="/store-checkout/:id"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <StoreCheckout />
                </Suspense>
              }
            />
            <Route
              path="/store-checkout/details/:id"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <GuestCheckoutDetails />
                </Suspense>
              }
            />
            <Route
              path="/restaurant-checkout/:id"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <RestaurantCheckout />
                </Suspense>
              }
            />
            <Route
              path="/restaurant-checkout/details/:id"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <RestaurantCheckoutDetails />
                </Suspense>
              }
            />
            <Route path="/cart" exact element={<Cart />} />
            <Route
              path="/cart/guest-checkout"
              exact
              element={<CartGuestCheckout />}
            />

            <Route path="/help" exact element={<Help />} />
            <Route path="/food-package" exact element={<FoodPackage />} />
            <Route
              path="/food-package/customise"
              exact
              element={<CustomisePlan />}
            />
            <Route
              path="/food-package/customise/:id"
              exact
              element={<CustomisePlan />}
            />
            <Route
              path="/food-package/customise/:id/meals"
              exact
              element={<ChooseMeals />}
            />
            <Route
              path="/food-package/:id/confirm"
              exact
              element={<ConfirmMeals />}
            />
            <Route
              path="/profile"
              exact
              element={
                <PrivateRoute>
                  <ProfileMobile />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/:active_tab"
              exact
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/:active_tab/:tab"
              exact
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/search" exact element={<Search />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/404" exact element={<Error404 />} />
            <Route path="/reset-link" exact element={<ResetPassword />} />
            <Route
              path="/create-business/:type"
              exact
              element={
                <PrivateRoute>
                  <OnboardBusiness />
                </PrivateRoute>
              }
            />
            <Route path="/Bank-transfer" exact element={<BankTransfer />} />
            <Route
              path="/create-business/store"
              exact
              element={
                <PrivateRoute>
                  <CreateStoreBusiness />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-restaurant"
              exact
              element={
                <PrivateRoute>
                  <CreateRestaurant />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-store"
              exact
              element={
                <PrivateRoute>
                  <CreateStore />
                </PrivateRoute>
              }
            />
            <Route path="/success" exact element={<Success />} />
            <Route
              exact
              path="/auth/inviteRegisteration"
              element={<InviteReg />}
            />
            <Route exact path="/pay-for-me/:id" element={<PayForMe />} />
            <Route path="/*" element={<Navigate to="/404" replace />} />
          </Routes>
        </section>
        <FooterCommon />
      </main>
    </React.Fragment>
  );
}
