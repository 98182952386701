import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/buttons/buttons";
import { PIMAGE } from "../../../../assets/images/pictures/images";
import InviteModal from "../../../../components/modals/admin/inviteModal";
import { showMyInvites } from "../../../../redux/invite/inviteActions";
import Empty from "../../../../assets/images/emptyState/invites.png";

export default function MyInvites() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState({});
  const { myInvites } = useSelector((state) => state.invite);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(showMyInvites());
  }, [dispatch]);

  return (
    <>
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0  px-6 bg-white dark:bg-[#121212] sm:h-[1000px] rounded-sm shadow-2xl">
        <div className="border-b-2 py-4 sm:py-6 mb-6 flex sm:block justify-between items-center">
          <p className="text-base font-medium text-gray-800 dark:text-white text-center">
            My Invites
          </p>
        </div>
        <div>
          <ul>
            {myInvites?.data?.length > 0 ? (
              myInvites?.data?.map((invite, key) => (
                <li
                  className="cursor-pointer border-b border-b-gray-100"
                  key={key}
                  onClick={() => {
                    setModalVisible(true);
                    setData(invite);
                  }}
                >
                  <div className="flex py-2 ">
                    <div className="py-4 mx-6">
                      <img
                        className=" w-12 h-12 sm:w-16 sm:h-16 "
                        src={invite?.business_picture || PIMAGE}
                        alt="avatar"
                      />
                    </div>

                    <div className="py-4 mt-3">
                      <p className=" text-sm sm:text-base font-bold text-gray-500 dark:text-white">
                        {" "}
                        {invite?.business.business_name}{" "}
                      </p>
                      <p className="text-xs sm:text-sm text-gray-400 dark:text-[#BCBCBC]">
                        Just sent you an invite to join their company
                      </p>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div className="my-10 mt-20  text-center">
                <img
                  src={Empty}
                  alt="empty state"
                  className="flex justify-center mx-auto mb-4"
                />
                <p className="font-bold mb-2">No Invites</p>
                <p className="text-sm text-gray-400">
                  Your Invite list shows here.
                </p>
              </div>
            )}
          </ul>
          <Button.Secondary
            type="button"
            title={"Back"}
            className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full"}
            onClick={() => navigate("/profile")}
          />
        </div>
        <InviteModal
          data={data}
          modalVisible={modalVisible}
          modalControl={() => setModalVisible(!modalVisible)}
        />
      </div>
    </>
  );
}
