import React, { useState, useRef } from "react";
import LeftInput from "./components/LeftInput";
import RightInput from "./components/RightInput";
import { getOutletFromCookies } from "../../../../../utils/Auth";
import ViewOutletPaymentModal from "../../../../../components/modals/payment/viewOutletPaymentModal";

export default function CreateRestaurantOutletOrder() {
  const outlet = getOutletFromCookies();
  const viewPaymentModalRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  let [recipient_phone, setRecipientPhone] = useState("");
  recipient_phone = recipient_phone?.slice(1);

  const [data, setData] = useState({
    business_id: outlet?.business_id,
    user_address_id: "1",
    user_id: "",
    restaurant_id: outlet?.id,
    recipient_name: "",
    recipient_email: "",
    recipient_phone: "",
    recipient_address: "",
    recipient_city: "",
    recipient_state: "",
    recipient_country: "",
    recipient_invoice: "",
    order_type: "standard",
    order_items: [],
    order_amount: "",
    order_discount: 0,
    recipient_service_area_id: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };
  return (
    <div data-test="createOrder-page">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] sm:h-[786px] rounded-md py-5 px-8">
          <LeftInput
            handleChange={handleChange}
            data={data}
            setData={setData}
            submitted={submitted}
            recipient_phone={recipient_phone}
            setRecipientPhone={setRecipientPhone}
          />
        </div>

        <RightInput
          data={data}
          setData={setData}
          submitted={submitted}
          setSubmitted={setSubmitted}
          handleChange={handleChange}
          recipient_phone={recipient_phone}
          viewPaymentModalRef={viewPaymentModalRef}
        />
      </div>
      <ViewOutletPaymentModal ref={viewPaymentModalRef} visibility={false} />
    </div>
  );
}
