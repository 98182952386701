import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import Button from "../../buttons/buttons";
import Copy from "../../../assets/images/icons/copy-outline.svg";
import CLOSE from "../../../assets/images/icons/close.svg";
import { copyTextToClipboard } from "../../helpers";
import PaymentSkeleton from "../../skeleton/payment";
import { showAPayment } from "../../../redux/payment/paymentActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default React.forwardRef(function ViewPaymentModal(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getId: (data) => getPaymentId(data),
  }));

  const dispatch = useDispatch();
  let [pfmLink, setPfmLink] = React.useState("");
  let [isOpen, setIsOpen] = React.useState(visibility);
  let [paymentId, setPaymentId] = React.useState();
  const { isLoading, singlePayment } = useSelector((state) => state.payment);

  useEffect(() => {
    if (paymentId) {
      dispatch(showAPayment(paymentId));
    }
  }, [dispatch, paymentId]);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function getPaymentId(data) {
    setPaymentId(data);
  }

  useEffect(() => {
    if (singlePayment?.pfm) {
      let base_url = window.location.origin;
      let text = `${base_url}/pay-for-me/${singlePayment?.pfm}`;
      setPfmLink(text);
    }
  }, [singlePayment?.pfm]);

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[640px] bg-white dark:bg-[#121212] rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full sm:p-8">
                    <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                      <button type="button" className="" onClick={closeModal}>
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-meduim text-black dark:text-white"
                      >
                        Payment ID: {singlePayment?.id}
                      </Dialog.Title>
                    </div>
                    <div className="border-b pt-3" />
                    <div className="mt-5 sm:mt-6">
                      {isLoading ? (
                        <PaymentSkeleton />
                      ) : (
                        <>
                          <div className="mt-3 sm:mt-2 shadow-lg px-4 rounded-md dark:border dark:border-[BCBCBC]">
                            <div className="border-b py-2 border-[#EAEAEA] dark:border-[BCBCBC]">
                              <h3 className="text-left font-bold text-lg">
                                Payment Details
                              </h3>
                            </div>
                            <div className="flex justify-between items-center py-3">
                              <span>Date</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                                {moment(singlePayment?.created_at).format(
                                  "DD MMM, YYYY"
                                )}
                              </span>
                            </div>
                            <div className="flex justify-between items-center py-2">
                              <span>Type</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC] capitalize">
                                {singlePayment?.type}
                              </span>
                            </div>
                            <div className="flex justify-between items-center py-2">
                              <span>Pay For Me ID</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                                {singlePayment?.pfm}
                              </span>
                            </div>
                            <div className="flex justify-between items-center py-2">
                              <span>Payment Link</span>

                              <div className="flex gap-1 max-w-[160px] w-full items-center justify-between">
                                <span className="font-bold text-[#1634A1] truncate">
                                  {pfmLink}
                                </span>
                                <img
                                  className="w-5 h-5 cursor-pointer"
                                  src={Copy}
                                  alt="copy-icon"
                                  onClick={() => copyTextToClipboard(pfmLink)}
                                />
                              </div>
                            </div>
                            <div className="flex justify-between items-center py-2">
                              <span>Payment Method</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC] capitalize">
                                {singlePayment?.method ?? "Not available"}
                              </span>
                            </div>
                            <div className="flex justify-between items-center py-3">
                              <span>Reference ID</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                                {singlePayment?.reference ?? "Not available"}
                              </span>
                            </div>
                          </div>

                          <div className="mt-5 sm:mt-7 py-2 shadow-lg px-4 rounded-md dark:border dark:border-[BCBCBC]">
                            <div className="border-b py-2 border-[#EAEAEA] dark:border-[BCBCBC]">
                              <h3 className="text-left font-bold text-lg">
                                Payment Summary
                              </h3>
                            </div>

                            <div className="flex justify-between items-center py-2">
                              <span>Amount</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                                ₦{numberWithCommas(singlePayment?.amount)}
                              </span>
                            </div>

                            <div className="flex justify-between items-center mt-4 py-2 border-t border-[#EAEAEA] dark:border-[BCBCBC]">
                              <span>Total</span>
                              <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                                ₦{numberWithCommas(singlePayment?.amount)}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <Button.Primary
                      title={"Back"}
                      type="button"
                      className="w-full py-3 mt-6"
                      onClick={closeModal}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
