import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import INSTA from "../../assets/images/icons/insta.svg";
import ORANGEARROW from "../../assets/images/icons/orange-arrow.svg";
import WHATSAPP from "../../assets/images/icons/whatsapp.svg";
import EMAIL from "../../assets/images/icons/email.svg";
import MESSAGE from "../../assets/images/icons/message.svg";
import TWITTER from "../../assets/images/icons/twitterOrange.svg";
import FACEBOOK from "../../assets/images/icons/f.svg";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export function ContactModal(props) {
  return (
    <>
      <Transition show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="p-5 sm:w-[500px] max-w-lg bg-white dark:bg-[#121212] rounded-3xl border shadow-xl sm:p-8 mx-auto">
                  <div className="sm:block absolute top-6 left-12 pt-4 pr-4">
                    <button onClick={props.onClose}>
                      <span className="sr-only">Close</span>
                      <div className=" w-3 h-3 sm:w-4 sm:h-4">
                        <ArrowLeftIcon />
                      </div>
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className=" text-center sm:ml-36 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black dark:text-white"
                      >
                        Contact Support
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4">
                    <div className="flow-root">
                      <ul className="divide-y divide-gray-200">
                        <a
                          href="sms:+2348140019523"
                          target="_blank"
                          rel="noreferrer"
                          className="py-3 sm:py-4 block"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              <img className="" src={MESSAGE} alt="email" />
                              <div className="ml-5 ">
                                <p className=" text-lg  text-start font-medium text-gray-900 dark:text-[#BCBCBC] truncate">
                                  Message
                                </p>
                              </div>
                            </div>

                            <div className="inline-flex items-center text-base font-semibold">
                              <img src={ORANGEARROW} alt="foward arrow" />
                            </div>
                          </div>
                        </a>

                        <a
                          href="mailto:hello@ogwugo.com"
                          target="_blank"
                          rel="noreferrer"
                          className="py-3 sm:py-4 block"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              <img className="" src={EMAIL} alt="email" />
                              <div className="ml-8 ">
                                <p className=" text-lg  text-start font-medium text-gray-900 dark:text-[#BCBCBC] truncate">
                                  Email
                                </p>
                              </div>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold">
                              <img src={ORANGEARROW} alt="foward arrow" />
                            </div>
                          </div>
                        </a>
                        <a
                          href="https://wa.me/2348140019523"
                          target="_blank"
                          rel="noreferrer"
                          className="py-3 sm:py-4 block"
                        >
                          <div className="flex items-center justify-between ">
                            <div className="flex">
                              <img className="" src={WHATSAPP} alt="Whatsapp" />
                              <div className="ml-7 ">
                                <p className=" text-lg  text-start font-medium text-gray-900 dark:text-[#BCBCBC] truncate">
                                  Whatsapp
                                </p>
                              </div>
                            </div>

                            <div className="inline-flex items-center text-base font-semibold">
                              <img src={ORANGEARROW} alt="foward arrow" />
                            </div>
                          </div>
                        </a>
                        <a
                          href="https://twitter.com/ogwugofood?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                          target="_blank"
                          rel="noreferrer"
                          className="py-3 sm:py-4 block"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              <img className="" src={TWITTER} alt="phone" />
                              <div className="ml-8 ">
                                <p className=" text-lg   font-medium text-gray-900 dark:text-[#BCBCBC]">
                                  Twitter
                                </p>
                              </div>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold">
                              <img src={ORANGEARROW} alt="foward arrow" />
                            </div>
                          </div>
                        </a>

                        <a
                          href="https://www.instagram.com/ogwugofood/"
                          target="_blank"
                          rel="noreferrer"
                          className="py-3 sm:py-4 block"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              <img className="" src={INSTA} alt="Instagram" />
                              <div className="ml-8 text-left ">
                                <p className=" text-lg  font-medium text-gray-900 dark:text-[#BCBCBC] truncate">
                                  Instagram
                                </p>
                              </div>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold">
                              <img src={ORANGEARROW} alt="foward arrow" />
                            </div>
                          </div>
                        </a>

                        <a
                          href="https://www.facebook.com/ogwugologistics/"
                          target="_blank"
                          rel="noreferrer"
                          className="py-3 sm:py-4 block"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              <img className="" src={FACEBOOK} alt="phone" />
                              <div className="ml-10 ">
                                <p className=" text-lg   font-medium text-gray-900 dark:text-[#BCBCBC]">
                                  Facebook
                                </p>
                              </div>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold">
                              <img src={ORANGEARROW} alt="foward arrow" />
                            </div>
                          </div>
                        </a>
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
