import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
//components
import SideNav from "../components/navigation/admin/SideNav";
import Header from "../components/navigation/admin/Header";
//pages
import Error404 from "../pages/common/Error404";
import Dashboard from "../pages/admin/dashboard/admin";
import Settings from "../pages/admin/settings";
import ManagePermission from "../pages/admin/settings/roles/ManagePermission";
import SingleUserRole from "../pages/admin/settings/userRoles/singleUserRole";
import SingleFeedback from "../pages/admin/settings/feedback/SingleFeedback";
import CreateBroadcastEmail from "../pages/admin/settings/broadcast/createEmail";
import CreateBroadcastSms from "../pages/admin/settings/broadcast/createSms";
import CreateBroadcastWhatsapp from '../pages/admin/settings/broadcast/createWhatsapp';
import CreateBroadcastNotification from "../pages/admin/settings/broadcast/createNotification";
import SingleBroadcast from "../pages/admin/settings/broadcast/SingleBroadcast";
import RecipientGroup from "../pages/admin/settings/broadcast/recipient/RecipientGroup";
import CreateRecipientGroup from "../pages/admin/settings/broadcast/recipient/CreateGroup";
import SingleRecipientGroup from "../pages/admin/settings/broadcast/recipient/SingleGroup";
import KYCUserDetails from "../pages/admin/settings/kyc/KYCUserDetails";
import KYCTierDetails from "../pages/admin/settings/kyc/KYCTierDetails";
import CreateKYCTier from "../pages/admin/settings/kyc/CreateKYCTier";
import Stores from "../pages/admin/stores";
import SingleStore from "../pages/admin/stores/SingleStore";
import InviteStore from "../pages/admin/stores/InviteStore";
import EditStoreAdmin from "../pages/admin/stores/editStore";
import Restaurants from "../pages/admin/restaurant";
import SingleRestaurant from "../pages/admin/restaurant/SingleRestaurant";
import InviteRestaurant from "../pages/admin/restaurant/InviteRestaurant";
import EditRestaurantAdmin from "../pages/admin/restaurant/editRestaurant";
import SingleMerchandise from "../pages/admin/merchandise/admin/SingleMerchandise";
import Carts from "../pages/admin/cart/admin";
import Food from "../pages/admin/food/admin";
import FoodDetail from "../pages/admin/food/admin/FoodDetail";
import CreateFoodAdmin from "../pages/admin/food/admin/CreateFood";
import EditFoodAdmin from "../pages/admin/food/admin/EditFood";
import RestaurantOrders from "../pages/admin/orders/restaurant";
import PackageRestaurantOrders from "../pages/admin/orders/package-restaurant";
import PackageStoreOrders from "../pages/admin/orders/package-store";
import CreateRestaurantOrder from "../pages/admin/orders/restaurant/CreateOrder";
import SingleRestaurantOrder from "../pages/admin/orders/restaurant/SingleOrder";
import EditRestaurantOrder from "../pages/admin/orders/restaurant/EditOrder";
import CreateFoodPackageOrder from "../pages/admin/orders/package-restaurant/CreateOrder";
import SingleFoodPackageOrder from "../pages/admin/orders/package-restaurant/SingleOrder";
import SingleOrder from "../pages/admin/orders/SingleOrder";
import CreateOrder from "../pages/admin/orders/CreateOrder";
import Users from "../pages/admin/users";
import InviteUser from "../pages/admin/users/InviteUser";
import UserProfile from "../pages/admin/users/UserProfile";
import FoodPackages from "../pages/admin/foodPackages";
import Coupons from "../pages/admin/coupon/index";
import PromoCode from "../pages/admin/promoCode";
import CreatePromoCode from "../pages/admin/promoCode/createPromoCode";
import SinglePromoCode from "../pages/admin/promoCode/singlePromoCode";
import SinglePackage from "../pages/admin/foodPackages/packages/SinglePackage";
import AddFoodPackage from "../pages/admin/foodPackages/packages/AddFoodPackage";
import EditFoodPackage from "../pages/admin/foodPackages/packages/EditFoodPackage";
import SubscriberDetail from "../pages/admin/foodPackages/users/SubscriberDetail";
import CreatePackageItem from "../pages/admin/foodPackages/items/CreatePackageItem";
import PackageItemDetails from "../pages/admin/foodPackages/items/PackageItemDetails";
import SingleCoupon from "../pages/admin/coupon/singleCoupon";
import CreateCoupon from "../pages/admin/coupon/createCoupon";
import Feature from "../pages/admin/feature";
import SingleFeaturedItem from "../pages/admin/feature/SingleFeaturedItem";
import OrdersReport from "../pages/admin/reports/orders";
import RestaurantOrdersReport from "../pages/admin/reports/orders/restaurant-order";
import RestaurantOrderStatistics from "../pages/admin/reports/orders/stats/RestaurantOrderStatistics";
import OrderRestaurantDetail from "../pages/admin/reports/orders/OrderRestaurantDetail";
import OrderStoreDetail from "../pages/admin/reports/orders/OrderStoreDetail";
import BusinessReport from "../pages/admin/reports/business";
import BusinessStatistics from "../pages/admin/reports/business/BusinessStatistics";
import BusinessReportDetail from "../pages/admin/reports/business/BusinessReportDetail";
import RestaurantReportDetail from "../pages/admin/reports/business/RestaurantReportDetail";
import StoreReportDetail from "../pages/admin/reports/business/StoreReportDetail";
import StoreOrdersReport from "../pages/admin/reports/orders/store-order";
import StoreOrderStatistics from "../pages/admin/reports/orders/stats/StoreOrderStatistics";
import UsersReport from "../pages/admin/reports/users";
import UserReportDetail from "../pages/admin/reports/users/userReportDetail";
import UserRestaurantOrdersReport from "../pages/admin/reports/users/orders/restaurant-order";
import UserStoreOrdersReport from "../pages/admin/reports/users/orders/store-order";
import UserFoodPackageOrdersReport from "../pages/admin/reports/users/orders/food-package-order";
import UserMerchandisePackageOrdersReport from "../pages/admin/reports/users/orders/merchandise-package-order";
import UserStatistics from "../pages/admin/reports/users/userStatistics";
import FeatureReport from "../pages/admin/reports/feature";
import FeatureReportDetail from "../pages/admin/reports/feature/featureReportDetail";
import FeatureStatistics from "../pages/admin/reports/feature/featureStatistics";
//Store owner admin
import Employees from "../pages/admin/employees/index";
import SingleEmployee from "../pages/admin/employees/SingleEmployee";
import InviteEmployee from "../pages/admin/employees/InviteEmployee";
import MerchandiseStore from "../pages/admin/merchandise/store";
import CreateMerchandise from "../pages/admin/merchandise/store/CreateMerchandise";
import EditMerchandise from "../pages/admin/merchandise/store/EditMerchandise";
import FoodStore from "../pages/admin/food/store";
import CreateFood from "../pages/admin/food/store/CreateFood";
import EditFood from "../pages/admin/food/store/EditFood";
import FoodDetails from "../pages/admin/food/store/FoodDetails";
import DashboardStore from "../pages/admin/dashboard/store";
import CartsStore from "../pages/admin/cart/store";
// import StoreOrders from "../pages/admin/orders/store";
import StoreSettings from "./../pages/admin/settings/store/Index";
// import SingleStoreOrder from "../pages/admin/orders/store/singleOrder";
import SingleStoreOrder from "../pages/admin/orders/outletOrders/store/singleOrder";
import CreateStoreOutletOrder from "../pages/admin/orders/outletOrders/store/createStoreOrder";
import Business from "../pages/admin/business";
import Shippers from "../pages/admin/shippers";
import SingleShipper from "../pages/admin/shippers/singleShipper";
import CreateShipper from "../pages/admin/shippers/create/createShipper";
import EditShipper from "../pages/admin/shippers/edit/editShipper";
import AdminMerchandise from "../pages/admin/merchandise/admin";
import SingleStoreMerchandise from "../pages/admin/merchandise/store/singleMerch";
import AdminStoreOrders from "../pages/admin/storeOrders";
import SingleAdminOrder from "../pages/admin/storeOrders/singleStoreOrder";
import CreateStoreOrder from "../pages/admin/storeOrders/createStoreOrder";
import EditStoreOrder from "../pages/admin/storeOrders/editStoreOrder";
import OutletOrders from "../pages/admin/orders/outletOrders";
// import OutletRestaurantOrders from "../pages/admin/orders/outletOrders/restauant";
import CreateRestaurantOutletOrder from "../pages/admin/orders/outletOrders/restauant/CreateOrder";
import OutletSingleRestaurantOrder from "../pages/admin/orders/outletOrders/restauant/SingleOrder";
import NewSettings from "../pages/admin/settings/indexx";
import SingleSlider from "../pages/admin/settings/sliders/singleSlider";
import NewStoreSettings from "../pages/admin/settings/store/indexx";
import EditBusiness from "../pages/admin/settings/store/business/editBusiness";
import CreateAdminMerchandise from "../pages/admin/merchandise/admin/createMerchandise";
//import SettingsCategories from "../pages/admin/settings/SettingsCategories";
import EditStoreInformation from "./../pages/admin/settings/store/store/editStore";
import EditRestaurant from "../pages/admin/settings/store/restaurant/editRestaurant";
import SingleEmployeeRole from "../pages/admin/settings/store/employeeRoles/SingleEmployeeRole";
import EditAdminMerchandise from "../pages/admin/merchandise/admin/editMerch";
import SingleBusiness from "../pages/admin/business/singleBusiness";
import SingleCart from "../pages/admin/cart/admin/singleCart";
import SingleAttribute from "../pages/admin/settings/store/attributes/singleAttributes";
import SingleStoreAttribute from "../pages/admin/settings/store/attributes/singleStoreAttribute";
import StoreFeature from "../pages/admin/feature/store";
import MerchandisePackages from "../pages/admin/merchPackages.js";
import SingleMerchandisePackage from "../pages/admin/merchPackages.js/packages/singlePackage";
import AddMerchPackage from "../pages/admin/merchPackages.js/packages/addMerchPackages";
import CreateMerchPackageItem from "../pages/admin/merchPackages.js/items/createPackages";
import ItemDetails from "./../pages/admin/merchPackages.js/items/packageItem";
import SingleStoreFeature from "../pages/admin/feature/store/singleFeature";
import Catalogue from "../pages/admin/catalogue";
import SingleCatalogue from "../pages/admin/catalogue/singleCatalogue";
import CreateCatalogue from "../pages/admin/catalogue/createCatalogue";
import OutletReport from "../pages/admin/reports/store";
import OutletReportStatistics from "../pages/admin/reports/store/stats";
import EditMerchPackage from "../pages/admin/merchPackages.js/packages/editPackages";
import Payments from "../pages/admin/payments";
import SinglePayment from "../pages/admin/payments/SinglePayment";

export default function AdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        <SideNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Static sidebar for desktop */}
        <div className="flex flex-1 flex-col md:pl-64 bg-[#F6F8FF] dark:bg-[#010101] min-h-screen">
          <Header setSidebarOpen={setSidebarOpen} />

          <section>
            {/* Body */}
            <main className="flex-1">
              <div className="py-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                  <Routes>
                    <Route path="*" exact element={<Error404 />} />
                    <Route path="/dashboard" exact element={<Dashboard />} />
                    <Route path="/settings" exact element={<NewSettings />} />
                    <Route
                      path="/settings/:active_tab"
                      exact
                      element={<Settings />}
                    />
                    <Route
                      path="/settings/:active_tab/:tab"
                      exact
                      element={<Settings />}
                    />
                    <Route
                      path="/settings/slider/:id"
                      exact
                      element={<SingleSlider />}
                    />
                    <Route
                      path="/settings/:active_tab/:tab/:page"
                      exact
                      element={<Settings />}
                    />
                    <Route
                      path="/settings/roles/:id"
                      exact
                      element={<ManagePermission />}
                    />
                    <Route
                      path="/settings/single-user-roles/:id"
                      exact
                      element={<SingleUserRole />}
                    />
                    <Route
                      path="/settings/feedback/details/:id"
                      exact
                      element={<SingleFeedback />}
                    />
                    <Route
                      path="/settings/broadcast-notification/create-email"
                      exact
                      element={<CreateBroadcastEmail />}
                    />
                    <Route
                      path="/settings/broadcast-notification/create-sms"
                      exact
                      element={<CreateBroadcastSms />}
                    />
                    <Route
                      path="/settings/broadcast-notification/create-whatsapp"
                      exact
                      element={<CreateBroadcastWhatsapp />}
                    />
                    <Route
                      path="/settings/broadcast-notification/create-notification"
                      exact
                      element={<CreateBroadcastNotification />}
                    />
                    <Route
                      path="/settings/broadcast-notification/notification/:id"
                      exact
                      element={<SingleBroadcast />}
                    />
                    <Route
                      path="/settings/broadcast-notification/recipient-groups"
                      exact
                      element={<RecipientGroup />}
                    />
                    <Route
                      path="/settings/broadcast-notification/create-recipient"
                      exact
                      element={<CreateRecipientGroup />}
                    />
                    <Route
                      path="/settings/broadcast-notification/recipient-groups/:id"
                      exact
                      element={<SingleRecipientGroup />}
                    />
                    <Route
                      path="/settings/kyc-verification/single-user/:id"
                      exact
                      element={<KYCUserDetails />}
                    />
                    <Route
                      path="/settings/kyc-verification/single-tier/:id"
                      exact
                      element={<KYCTierDetails />}
                    />
                    <Route
                      path="/settings/kyc-verification/create-tier"
                      exact
                      element={<CreateKYCTier />}
                    />
                    <Route path="/stores" exact element={<Stores />} />
                    <Route
                      path="/stores/:active_tab/:page"
                      exact
                      element={<Stores />}
                    />
                    <Route path="/stores/:id" exact element={<SingleStore />} />
                    <Route
                      path="/stores/edit/:id"
                      exact
                      element={<EditStoreAdmin />}
                    />
                    <Route
                      path="/store/invite"
                      exact
                      element={<InviteStore />}
                    />
                    <Route
                      path="/merchandise/:id/edit"
                      exact
                      element={<EditAdminMerchandise />}
                    />
                    <Route path="/business" exact element={<Business />} />
                    <Route
                      path="/business/:active_tab/:page"
                      exact
                      element={<Business />}
                    />
                    <Route
                      path="/business/:id"
                      exact
                      element={<SingleBusiness />}
                    />
                    <Route
                      path="/restaurants"
                      exact
                      element={<Restaurants />}
                    />
                    <Route
                      path="/restaurants/:active_tab/:page"
                      exact
                      element={<Restaurants />}
                    />
                    <Route
                      path="/restaurants/:id"
                      exact
                      element={<SingleRestaurant />}
                    />
                    <Route
                      path="/restaurant/invite"
                      exact
                      element={<InviteRestaurant />}
                    />
                    <Route
                      path="/restaurants/edit/:id"
                      exact
                      element={<EditRestaurantAdmin />}
                    />
                    <Route path="/coupons" exact element={<Coupons />} />
                    <Route
                      path="/coupons/:active_tab/:page"
                      exact
                      element={<Coupons />}
                    />
                    <Route
                      path="/coupons/:id"
                      exact
                      element={<SingleCoupon />}
                    />
                    <Route
                      path="/create-coupon"
                      exact
                      element={<CreateCoupon />}
                    />
                    <Route path="/promo" exact element={<PromoCode />} />
                    <Route
                      path="/promo/:active_tab/:page"
                      exact
                      element={<PromoCode />}
                    />
                    <Route
                      path="/create-promo"
                      exact
                      element={<CreatePromoCode />}
                    />
                    <Route
                      path="/promo/:id"
                      exact
                      element={<SinglePromoCode />}
                    />
                    <Route path="/shippers" exact element={<Shippers />} />
                    <Route
                      path="/shippers/:active_tab/:page"
                      exact
                      element={<Shippers />}
                    />
                    <Route
                      path="/shippers/:id"
                      exact
                      element={<SingleShipper />}
                    />
                    <Route
                      path="/create-shipper"
                      exact
                      element={<CreateShipper />}
                    />
                    <Route
                      path="/shippers/edit/:id"
                      exact
                      element={<EditShipper />}
                    />
                    <Route
                      path="/merchandise"
                      exact
                      element={<AdminMerchandise />}
                    />
                    <Route
                      path="/merchandise/:active_tab/:page"
                      exact
                      element={<AdminMerchandise />}
                    />
                    <Route
                      path="/merchandise/create"
                      exact
                      element={<CreateAdminMerchandise />}
                    />
                    <Route
                      path="/merchandise/:id"
                      exact
                      element={<SingleMerchandise />}
                    />
                    <Route path="/carts" exact element={<Carts />} />
                    <Route path="/carts/:page" exact element={<Carts />} />
                    <Route
                      path="/carts/details/:id"
                      exact
                      element={<SingleCart />}
                    />
                    <Route path="/food" exact element={<Food />} />
                    <Route
                      path="/food/:active_tab/:page"
                      exact
                      element={<Food />}
                    />
                    <Route path="/food/:id" exact element={<FoodDetail />} />
                    <Route
                      path="/food/create"
                      exact
                      element={<CreateFoodAdmin />}
                    />
                    <Route
                      path="/food/edit/:id"
                      exact
                      element={<EditFoodAdmin />}
                    />
                    {/* <Route
                      path="/orders/restaurant"
                      exact
                      element={<RestaurantOrders />}
                    />
                    <Route
                      path="/orders/restaurant/:active_tab/:page"
                      exact
                      element={<RestaurantOrders />}
                    /> */}
                    <Route
                      path="/restaurant-orders"
                      exact
                      element={<RestaurantOrders />}
                    />
                    <Route
                      path="/restaurant-orders/:active_tab"
                      exact
                      element={<RestaurantOrders />}
                    />
                    <Route
                      path="/restaurant-orders/:active_tab/:page"
                      exact
                      element={<RestaurantOrders />}
                    />
                    <Route
                      path="/restaurant-orders/create"
                      exact
                      element={<CreateRestaurantOrder />}
                    />
                    <Route
                      path="/restaurant-orders/edit/:id"
                      exact
                      element={<EditRestaurantOrder />}
                    />
                    <Route
                      path="/restaurant-orders/details/:id"
                      exact
                      element={<SingleRestaurantOrder />}
                    />
                    <Route
                      path="/orders/package-food"
                      exact
                      element={<PackageRestaurantOrders />}
                    />
                    <Route
                      path="/orders/package-food/:active_tab/:page"
                      exact
                      element={<PackageRestaurantOrders />}
                    />
                    <Route
                      path="/orders/package-merchandise"
                      exact
                      element={<PackageStoreOrders />}
                    />
                    <Route
                      path="/orders/package-merchandise/:active_tab/:page"
                      exact
                      element={<PackageStoreOrders />}
                    />
                    <Route
                      path="/orders/package-food/create"
                      exact
                      element={<CreateFoodPackageOrder />}
                    />
                    <Route
                      path="/orders/package-food/:id"
                      exact
                      element={<SingleFoodPackageOrder />}
                    />
                    <Route
                      path="/store-orders"
                      exact
                      element={<AdminStoreOrders />}
                    />
                    <Route
                      path="/store-orders/:active_tab"
                      exact
                      element={<AdminStoreOrders />}
                    />
                    <Route
                      path="/store-orders/:active_tab/:page"
                      exact
                      element={<AdminStoreOrders />}
                    />
                    <Route
                      path="/store-orders/create"
                      exact
                      element={<CreateStoreOrder />}
                    />
                    <Route
                      path="/store-orders/edit/:id"
                      exact
                      element={<EditStoreOrder />}
                    />
                    <Route
                      path="/store-orders/details/:id"
                      exact
                      element={<SingleAdminOrder />}
                    />
                    <Route path="/order/:id" exact element={<SingleOrder />} />
                    <Route
                      path="/order/create"
                      exact
                      element={<CreateOrder />}
                    />
                    <Route path="/users" exact element={<Users />} />
                    <Route
                      path="/users/:active_tab"
                      exact
                      element={<Users />}
                    />
                    <Route
                      path="/users/:active_tab/:page"
                      exact
                      element={<Users />}
                    />
                    <Route
                      path="/users/details/:id"
                      exact
                      element={<UserProfile />}
                    />
                    <Route path="/user/invite" exact element={<InviteUser />} />
                    <Route
                      path="/food-packages"
                      exact
                      element={<FoodPackages />}
                    />
                    <Route
                      path="/food-packages/:active_tab"
                      exact
                      element={<FoodPackages />}
                    />
                    <Route
                      path="/food-packages/:active_tab/:page"
                      exact
                      element={<FoodPackages />}
                    />
                    <Route
                      path="/food-packages/package/:id"
                      exact
                      element={<SinglePackage />}
                    />
                    <Route
                      path="/food-packages/add"
                      exact
                      element={<AddFoodPackage />}
                    />
                    <Route
                      path="/food-packages/edit/:id"
                      exact
                      element={<EditFoodPackage />}
                    />
                    <Route
                      path="/food-packages/subscribers/:id"
                      exact
                      element={<SubscriberDetail />}
                    />
                    <Route
                      path="/food-packages/item/create"
                      exact
                      element={<CreatePackageItem />}
                    />
                    <Route
                      path="/food-packages/item/:id"
                      exact
                      element={<PackageItemDetails />}
                    />
                    <Route
                      path="/merchandise-packages"
                      exact
                      element={<MerchandisePackages />}
                    />
                    <Route
                      path="/merchandise-packages/:active_tab"
                      exact
                      element={<MerchandisePackages />}
                    />
                    <Route
                      path="/merchandise-packages/:active_tab/:page"
                      exact
                      element={<MerchandisePackages />}
                    />
                    <Route
                      path="/merchandise-packages/package/:id"
                      exact
                      element={<SingleMerchandisePackage />}
                    />
                    <Route
                      path="/merchandise-packages/add"
                      exact
                      element={<AddMerchPackage />}
                    />
                    <Route
                      path="/merchandise-packages/subscribers/:id"
                      exact
                      element={<MerchandisePackages />}
                    />
                    <Route
                      path="/merchandise-packages/item/create"
                      exact
                      element={<CreateMerchPackageItem />}
                    />
                    <Route
                      path="/merchandise-packages/edit/:id"
                      exact
                      element={<EditMerchPackage />}
                    />
                    <Route
                      path="/merchandise-packages/item/:id"
                      exact
                      element={<ItemDetails />}
                    />
                    <Route path="/feature" exact element={<Feature />} />
                    <Route
                      path="/feature/:active_tab/:page"
                      exact
                      element={<Feature />}
                    />
                    <Route
                      path="/feature/:id"
                      exact
                      element={<SingleFeaturedItem />}
                    />
                    <Route path="/catalogue" exact element={<Catalogue />} />
                    <Route
                      path="/catalogue/:active_tab/:page"
                      exact
                      element={<Catalogue />}
                    />
                    <Route
                      path="/catalogue/:id"
                      exact
                      element={<SingleCatalogue />}
                    />
                    <Route
                      path="/catalogue/create"
                      exact
                      element={<CreateCatalogue />}
                    />
                    <Route path="/payments" exact element={<Payments />} />
                    <Route
                      path="/payments/:active_tab/:page"
                      exact
                      element={<Payments />}
                    />
                    <Route
                      path="/payments/:active_tab/:page"
                      exact
                      element={<Payments />}
                    />
                    <Route
                      path="/payments/:id"
                      exact
                      element={<SinglePayment />}
                    />
                    <Route
                      path="/orders-report"
                      exact
                      element={<OrdersReport />}
                    />
                    <Route
                      path="/orders-report/:active_tab"
                      exact
                      element={<OrdersReport />}
                    />
                    <Route
                      path="/orders-report/:active_tab/:page"
                      exact
                      element={<OrdersReport />}
                    />
                    <Route
                      path="/orders-report/restaurant/:outlet/:active_tab/:page"
                      exact
                      element={<RestaurantOrdersReport />}
                    />
                    <Route
                      path="/orders-report/restaurant/:id/statistics"
                      exact
                      element={<RestaurantOrderStatistics />}
                    />
                    <Route
                      path="/report/restaurant/:id"
                      exact
                      element={<RestaurantReportDetail />}
                    />

                    <Route
                      path="//orders-report/restaurant/details/:id"
                      exact
                      element={<OrderRestaurantDetail />}
                    />

                    <Route
                      path="/report/store-order/:id"
                      exact
                      element={<OrderStoreDetail />}
                    />
                    <Route
                      path="/report/store/:id"
                      exact
                      element={<StoreReportDetail />}
                    />
                    <Route
                      path="/orders-report/store/:outlet/:active_tab/:page"
                      exact
                      element={<StoreOrdersReport />}
                    />
                    <Route
                      path="/orders-report/store/:id/statistics"
                      exact
                      element={<StoreOrderStatistics />}
                    />
                    <Route
                      path="/business-report"
                      exact
                      element={<BusinessReport />}
                    />
                    <Route
                      path="/business-report/:id"
                      exact
                      element={<BusinessReportDetail />}
                    />
                    <Route
                      path="/business-report/:active_tab/:page"
                      exact
                      element={<BusinessReport />}
                    />
                    <Route
                      path="/business-report/:id/statistics"
                      exact
                      element={<BusinessStatistics />}
                    />
                    <Route
                      path="/users-report"
                      exact
                      element={<UsersReport />}
                    />
                    <Route
                      path="/users-report/:active_tab/:page"
                      exact
                      element={<UsersReport />}
                    />
                    <Route
                      path="/users-report/:id"
                      exact
                      element={<UserReportDetail />}
                    />
                    <Route
                      path="/users-report/:id/restaurant-orders/:active_tab/:page"
                      exact
                      element={<UserRestaurantOrdersReport />}
                    />
                    <Route
                      path="/users-report/:id/store-orders/:active_tab/:page"
                      exact
                      element={<UserStoreOrdersReport />}
                    />
                    <Route
                      path="/users-report/:id/food-package-orders/:active_tab/:page"
                      exact
                      element={<UserFoodPackageOrdersReport />}
                    />
                    <Route
                      path="/users-report/:id/merchandise-package-orders/:active_tab/:page"
                      exact
                      element={<UserMerchandisePackageOrdersReport />}
                    />
                    <Route
                      path="/users-report/:id/statistics"
                      exact
                      element={<UserStatistics />}
                    />
                    <Route
                      path="/feature-report"
                      exact
                      element={<FeatureReport />}
                    />
                    <Route
                      path="/feature-report/:active_tab/:page"
                      exact
                      element={<FeatureReport />}
                    />
                    <Route
                      path="/feature-report/:id"
                      exact
                      element={<FeatureReportDetail />}
                    />
                    <Route
                      path="/feature-report/:id/statistics"
                      exact
                      element={<FeatureStatistics />}
                    />
                    {/* Store owner admin routes */}
                    <Route
                      path="/:name/employees"
                      exact
                      element={<Employees />}
                    />
                    <Route
                      path="/:name/employees/:active_tab/:page"
                      element={<Employees />}
                    />
                    <Route
                      path="/:name/employees/:id"
                      exact
                      element={<SingleEmployee />}
                    />
                    <Route
                      path="/:name/employees/invite"
                      exact
                      element={<InviteEmployee />}
                    />
                    <Route
                      path="/:name/features"
                      exact
                      element={<StoreFeature />}
                    />
                    <Route
                      path="/:name/features/:active_tab/:page"
                      exact
                      element={<StoreFeature />}
                    />
                    <Route
                      path="/:name/features/:id"
                      exact
                      element={<SingleStoreFeature />}
                    />
                    <Route
                      path="/:name/merchandise"
                      exact
                      element={<MerchandiseStore />}
                    />
                    <Route
                      path="/:name/merchandise/:active_tab/:page"
                      exact
                      element={<MerchandiseStore />}
                    />
                    <Route
                      path="/:name/merchandise/create"
                      exact
                      element={<CreateMerchandise />}
                    />
                    <Route
                      path="/:name/merchandise/:id/edit"
                      exact
                      element={<EditMerchandise />}
                    />
                    <Route
                      path="/:name/merchandise/:id"
                      exact
                      element={<SingleStoreMerchandise />}
                    />
                    <Route path="/:name/food" exact element={<FoodStore />} />
                    <Route
                      path="/:name/food/:active_tab/:page"
                      exact
                      element={<FoodStore />}
                    />
                    <Route
                      path="/:name/food/create"
                      exact
                      element={<CreateFood />}
                    />
                    <Route
                      path="/:name/food/details/:id"
                      exact
                      element={<FoodDetails />}
                    />
                    <Route
                      path="/:name/food/edit/:id"
                      exact
                      element={<EditFood />}
                    />
                    <Route
                      path="/:name/dashboard"
                      exact
                      element={<DashboardStore />}
                    />
                    <Route path="/:name/carts" exact element={<CartsStore />} />
                    <Route
                      path="/:name/carts/:active_tab"
                      exact
                      element={<CartsStore />}
                    />
                    <Route
                      path="/:name/settings"
                      exact
                      element={<NewStoreSettings />}
                    />
                    <Route
                      path="/:name/settings/single-attribute/:id"
                      exact
                      element={<SingleAttribute />}
                    />
                    <Route
                      path="/:name/settings/single-attribute/:id"
                      exact
                      element={<SingleStoreAttribute />}
                    />
                    <Route
                      path="/:name/settings/attribute/:id"
                      exact
                      element={<SingleStoreAttribute />}
                    />
                    <Route
                      path="/:name/settings/:active_tab"
                      exact
                      element={<StoreSettings />}
                    />
                    <Route
                      path="/:name/settings/store/:id/edit"
                      exact
                      element={<EditStoreInformation />}
                    />
                    <Route
                      path="/:name/settings/restaurant/:id/edit"
                      exact
                      element={<EditRestaurant />}
                    />
                    <Route
                      path="/:name/settings/:active_tab/:page"
                      exact
                      element={<StoreSettings />}
                    />
                    <Route
                      path="/:name/settings/:active_tab/edit"
                      exact
                      element={<EditBusiness />}
                    />
                    <Route
                      path="/:name/settings/employee-roles/user/:id"
                      exact
                      element={<SingleEmployeeRole />}
                    />
                    <Route
                      path="/:name/orders"
                      exact
                      element={<OutletOrders />}
                    />
                    <Route
                      path="/:name/orders/:active_tab/:page"
                      exact
                      element={<OutletOrders />}
                    />
                    <Route
                      path="/:name/order/restaurant/:id"
                      exact
                      element={<OutletSingleRestaurantOrder />}
                    />
                    <Route
                      path="/:name/order/restaurant/create-order"
                      exact
                      element={<CreateRestaurantOutletOrder />}
                    />
                    <Route
                      path="/:name/orders/:id"
                      exact
                      element={<SingleStoreOrder />}
                    />
                    <Route
                      path="/:name/order/store/create-order"
                      exact
                      element={<CreateStoreOutletOrder />}
                    />
                    <Route
                      path="/:name/report"
                      exact
                      element={<OutletReport />}
                    />
                    <Route
                      path="/:name/report/statistics"
                      exact
                      element={<OutletReportStatistics />}
                    />
                  </Routes>
                </div>
              </div>
            </main>
          </section>
        </div>
      </div>
    </>
  );
}
