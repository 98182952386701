import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOutletFromCookies,
  getOutletTypeFromCookies,
} from "../../../../utils/Auth";

export default function NewStoreSettings() {
  const navigate = useNavigate();
  const { active_tab } = useParams();

  const outlet = getOutletFromCookies();
  const type = getOutletTypeFromCookies();
  const titles = [
    {
      name: "Attributes",
      href: "attributes",
      text: "Characteristics of your products",
    },
    {
      name: "Token",
      href: "token",
      text: "Create and generate tokens.",
    },
    {
      name: "Opening Hours",
      href: "opening",
      text: "Let’s customers know availability.",
    },
    {
      name: "Business Information",
      href: "business_restaurant" || "business_store",
      text: "View or change business details.",
    },
    {
      name: type === "store" ? "Store Information" : "Restaurant Information",
      href: type === "store" ? "store" : "restaurant",
      text:
        type === "store"
          ? "View or change store details"
          : "View or change restaurant details",
    },
    {
      name: "Help Center",
      href: "help",
      text: "Contact support.",
    },
    {
      name: "Privacy Policy",
      href: "privacy",
      text: "Learn how we manage your data.",
    },
    {
      name: "Manage Employee Roles",
      href: "employee-roles/1",
      text: "Assign & Un-assign  roles to employees.",
    },
    {
      name: "Business Financial",
      href: "business-financials",
      text: "Settings for business finance.",
    },
  ];

  const toggle = (tab) => {
    if (active_tab !== tab) {
      // console.log("active_tab", active_tab);
      if (tab?.href === "attributes") {
        navigate(
          `/admin/${outlet?.store_name || outlet?.restaurant_name}/settings/${
            tab.href
          }/1`,
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        );
      } else {
        navigate(
          `/admin/${outlet?.store_name || outlet?.restaurant_name}/settings/${
            tab.href
          }`,
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        );
      }
    }
  };

  return (
    <div>
      <div className="bg-white dark:bg-[#121212] min-h-[906px] rounded-lg px-6 py-6">
        <div>
          <h2 className="text-2xl dark:text-[#0148FF] font-bold py-3 px-2">
            Settings
          </h2>
          <p className="text-[#A3AED0] dark:text-white  py-2 px-2 mb-6">
            The settings page on the website provides a centralized location for
            the store owner to manage various aspects of the business,the
            business information,store information and support needs. The
            purpose of the page is to make it easier for store owners to manage
            their information and ensure that products and business information
            is up-to-date and accurate.
          </p>
        </div>
        <div className="grid sm:grid-cols-4 gap-5 sm:gap-8">
          {titles.map((title, i) => (
            <div
              key={i}
              className={
                "w-full sm:max-w-[268px] sm:h-24 rounded-xl shadow-lg px-2 py-6  bg-white dark:bg-[#010101] cursor-pointer"
              }
              onClick={() => {
                toggle(title);
              }}
            >
              <div className="mb-2 sm:mb-6 items-center">
                <p className="text-sm text-[#a3aed0] dark:text-white font-medium">
                  {title.text}
                </p>
                <p className="text-[#2B3674] dark:text-[#0148FF]">
                  {title.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
