import React from "react";
import { Dialog } from "@headlessui/react";
import CLOSE from "../../../../assets/images/icons/close.svg";
import RegisterForm from "../../../forms/auth/registerForm";

export default function FormSection1({ formWizardRef, props }) {
  return (
    <Dialog.Panel className="relative sm:w-[600px] bg-white dark:bg-[#010101] rounded-3xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full sm:p-8">
      <div className=" sm:block absolute top-2 right-2 pt-4 pr-4">
        <button
          type="button"
          className="bg-[#ffeae5] rounded-md p-2 sm:p-3 "
          onClick={props.onClose}
        >
          <span className="sr-only">Close</span>
          <img src={CLOSE} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className=" md:mt-10  sm:mt-10  text-left">
          <Dialog.Title
            as="h3"
            className="text-xl font-bold text-black dark:text-white"
          >
            Create an account or login
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm font-normal text-gray-500">
              Create a new Ogwugo account or login below
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-8">
        <RegisterForm formWizardRef={formWizardRef} props={props} />
        <button
          className="text-base font-bold flex justify-center mb-10"
          onClick={() => {
            formWizardRef.current.goTo(0);
          }}
        >
          Already have an account?{" "}
          <span className="text-primary ml-1 cursor-pointer">Login</span>
        </button>
      </div>
    </Dialog.Panel>
  );
}
