import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../../components/inputs/inputs";
import Customer from "../../../../../../assets/images/icons/profile.svg";
import CreateCustomerModal from "../../../../../../components/modals/admin/createCustomerModal";
import { searchContactsAction } from "../../../../../../redux/contacts/contactActions";
import contactType from "../../../../../../redux/contacts/contactTypes";
import { filterAddressesAction } from "../../../../../../redux/address/addressActions";

export default function LeftSide({
  handleChange,
  data,
  setData,
  submitted,
  recipient_phone,
  setRecipientPhone,
}) {
  const dispatch = useDispatch();
  const { recipient_name } = data;

  const [searchValue, setSearchValue] = useState({ search: "" });
  const [userAddress, setUserAddress] = useState([]);

  let { searchContact } = useSelector((state) => state.contact);
  // let { allAddresses } = useSelector((state) => state.address);

  const handleSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setSearchValue({ ...searchValue, [name]: value });
    setData((data) => ({ ...data, recipient_name: value }));
    if (value.length >= 3) {
      dispatch(
        searchContactsAction({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      searchContact = { data: [] };
      dispatch({
        type: contactType["SEARCH_CONTACTS"],
        payLoad: searchContact,
      });
      setUserAddress([]);
    }
  };

  async function fillUser(result) {
    setData((data) => ({
      ...data,
      user_id: result.id ?? "",
      user_address_id: "1",
      recipient_name: result.name ?? "",
      recipient_phone: result.phone ?? "",
    }));

    try {
      const response = await dispatch(
        filterAddressesAction({ user_id: result.id })
      );
      if (response?.status === "success") {
        //get the user addresses
        if (response?.data?.data?.length > 0) {
          setUserAddress(response?.data?.data);
        }
      } else {
        setUserAddress([]);
      }
    } catch (error) {}

    setSearchValue((searchValue) => ({
      ...searchValue,
      search: result.recipient_name,
    }));
    searchContact = { data: [] };
    dispatch({ type: "SEARCH_USERS", searchContact });
  }

  return (
    <>
      <div className="border-b border-[#E4E4F3] dark:border-none py-3 text-center">
        <h3 className="text-base text-gray-500 dark:text-white font-medium">
          Create New Order
        </h3>
      </div>
      <div className="text-right mt-4">
        <CreateCustomerModal
          handleChange={handleChange}
          data={data ? data : []}
          setData={setData}
          recipient_phone={recipient_phone}
          setRecipientPhone={setRecipientPhone}
        />
      </div>

      <form className="mt-2">
        <div className="mt-2 mb-3 relative">
          <input
            type={"search"}
            name={"recipient_name"}
            htmlFor={"recipient_name"}
            autoComplete="off"
            className={
              "mt-2 block pl-3 pr-10 w-full text-base bg-gray-100 dark:bg-transparent focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
            }
            placeholder={"Search user by name, phone Number, email..."}
            onChange={handleSearch}
            value={recipient_name}
          />
          <img
            src={Customer}
            alt="customer"
            className=" fa-search absolute top-2 right-4"
          />
          {submitted && !recipient_name && (
            <div className="text-primary text-xs ">User is required</div>
          )}
        </div>

        {searchContact?.data &&
          Array.isArray(searchContact.data) &&
          searchContact?.data?.map((result, index) => (
            <div
              className="p-3 border border-primary mb-2"
              id={result.id}
              key={index}
              onClick={() => fillUser(result)}
            >
              <div className=" grid-cols-3">
                <div className="col-span-1">
                  <div className="">
                    <h6 className="text-sm font-meduim">Name: {result.name}</h6>
                  </div>
                </div>
                <div className="col-span-1">
                  <div>
                    <h6 className="text-sm font-meduim">
                      Phone: {result.user_phone}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {userAddress?.length > 0 && (
          <Input.Select
            title={`${recipient_name}: Preferred Address`}
            name={"user_address_id"}
            onChange={handleChange}
            htmlFor={"user_address_id"}
          >
            <option>Select user address</option>
            {userAddress?.length > 0 &&
              userAddress?.map((address) => (
                <option key={address?.id} value={address?.id}>
                  {address?.title}
                </option>
              ))}
          </Input.Select>
        )}

        <div className="grid grid-cols-2 gap-8">
          <Input.Select
            title={"Discount"}
            name={"order_discount"}
            htmlFor={"order_discount"}
          >
            <option value={"Amount"}>Amount</option>
          </Input.Select>
          <Input.Number
            title={"."}
            htmlFor={"order_discount"}
            name={"order_discount"}
            placeholder={"0"}
            onChange={handleChange}
          />
        </div>

        <Input.Select
          title={"Invoice Delivery Option"}
          name={"recipient_invoice"}
          onChange={handleChange}
          htmlFor={"recipient_invoice"}
        >
          <option>Select the invoice delivery option</option>
          <option value={"mail"}>Mail</option>
          <option value={"sms"}>Sms</option>
        </Input.Select>
      </form>
    </>
  );
}
