import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/tableCols1";
import { Pagination } from "../../../../components/pagination/pagination";
import { searchFeaturedProducts } from "../../../../redux/feature/featureActions";
import TableSkeleton from "../../../../components/skeleton/table";
import { filterFeaturedProducts } from "./../../../../redux/feature/featureActions";
import EmptyState from "../../../../assets/images/emptyState/feature.svg";
import {
  getTeamFromCookies,
  getOutletFromCookies,
} from "./../../../../utils/Auth";
import { getAllFeaturedNoPagination } from "../../../../redux/feature/featureActions";
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import { Beat } from "../../../../plugins/spinners.plugin";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterFeatureDropDown from "../../../../components/dropDown/filter/filterFeature";
import moment from "moment";

export default function ActiveFeatured() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  const team = getTeamFromCookies();
  const outlet = getOutletFromCookies();

  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);
  const [input, setInput] = useState({
    search: "",
  });

  const { allFeatured, filtering, searching, search, downloading } =
    useSelector((state) => state.feature);

  const [data, setData] = useState([]);
  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    business_id: team,
    outlet_id: outlet?.id,
    status: "inactive",
    // start_date: lastMonth,
    end_date: new Date(),
  });
  useEffect(() => {
    dispatch(filterFeaturedProducts(payLoad, params.page));
  }, [dispatch, payLoad, params]);

  useEffect(() => {
    if (!params) {
      navigate(
        `/admin/${
          outlet.restaurant_name || outlet?.store_name
        }/features/inactive/1`
      );
    }
  }, [params, outlet, navigate]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((featured, index) => {
        let single = {
          index: index + 1,
          start:
            featured?.featured_end_date === null ? (
              <p>{"Not available"}</p>
            ) : (
              <span>
                {moment(featured?.featured_start_date).format("MMM DD, YYYY")}
              </span>
            ),
          end:
            featured?.featured_end_date === null ? (
              <p>{"Not available"}</p>
            ) : (
              <span>
                {moment(featured?.featured_end_date).format("MMM DD, YYYY")}
              </span>
            ),

          amount: featured?.featured_amount,
          duration: featured?.featured_duration_in_hours,
          discounts: featured?.featured_discount,
          featured: featured,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((featured, index) => {
        let single = {
          index: index + 1,
          start:
            featured?.featured_end_date === null
              ? "Not available"
              : moment(featured?.featured_start_date).format("MMM DD, YYYY"),
          end:
            featured?.featured_end_date === null
              ? "Not available"
              : moment(featured?.featured_end_date).format("MMM DD, YYYY"),
          amount: featured?.featured_amount,
          duration: featured?.featured_duration_in_hours,
          discounts: featured?.featured_discount,
        };
        outputArray.push(single);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allFeatured?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeatured]);

  const goToSingle = (params) => {
    let id = params?.single?.featured?.id;
    navigate(
      `/admin/${outlet.restaurant_name || outlet?.store_name}/features/${id}`,
      {
        state: { featured: params?.single?.featured },
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleDownload = async () => {
    const response = await dispatch(
      getAllFeaturedNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Inactive features.xlsx",
        download: true,
      });
    }
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allFeatured?.data);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchFeaturedProducts(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.index",
          },
          {
            Header: "Start Date",
            accessor: "single.start",
          },
          {
            Header: "End Date",
            accessor: "single.end",
          },
          {
            Header: "Amount",
            accessor: "single.amount",
          },
          {
            Header: "Duration",
            accessor: "single.duration",
          },

          {
            Header: "Discounts",
            accessor: "single.discounts",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="inactive-feature-page">
      <Card.TableFilter
        title={"Feature"}
        onChange={handleChange}
        searchPlaceholder={"Amount, duration"}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterFeatureDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2000"} /> : "Download CSV"}
          disabled={!data}
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]" />
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full"
            onClick={goToSingle}
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Feature</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any feature yet.
            </p>
          </div>
        )}
        <Pagination data={allFeatured} route={"admin/feature/inactive"} />
      </div>
    </div>
  );
}
