import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/buttons";
import Delete from "../../../assets/images/icons/rafiki.svg";
import { DeleteAccountModal } from "../../../components/modals/deleteUserModal";

export default function DeleteUser() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 pb-10 bg-white dark:bg-[#121212] sm:min-h-[1000px] rounded-sm shadow-2xl">
      <div className="border-b-2 py-4 sm:py-6 mb-6 flex sm:block justify-between items-center">
        <h3 className="text-base font-medium text-gray-800 dark:text-white text-center">
          Delete Your Account?
        </h3>
      </div>

      <div className="mb-5">
        <img src={Delete} alt="delete" />
        <p className="text-xl font-medium mt-5 text-center">
          We are sorry to see you go, what could we have done to prevent your
          exit?{" "}
        </p>
      </div>

      <div className="flex flex-col items-center justify-center">
        <button
          type="submit"
          className="w-full sm:w-[535px] inline-flex justify-center h-12 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-12 sm:text-sm"
          onClick={() => setIsOpen(true)}
        >
          Delete Account
        </button>

        <Button.Secondary
          type="button"
          title={"Back"}
          className={"sm:hidden rounded-md mb-8 mt-6 sm:mb-0 w-full"}
          onClick={() => navigate("/profile")}
        />
      </div>
      <DeleteAccountModal show={isOpen} onClose={closeModal} />
    </div>
  );
}
