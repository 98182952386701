import { Tab } from "@headlessui/react";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ data, SearchFood }) {
  const location = useLocation();

  const handleChange = (e) => {
    const { value } = e.target;
    e.preventDefault();
    //search for value in restaurants
    SearchFood(value);
  };

  return (
    <div className="w-full sm:px-0" data-test="tabComponent">
      <Tab.Group>
        <div className="sm:rounded-md sm:max-w-7xl bg-white dark:bg-[#121212] border dark:border-none shadow-lg pt-7 p-2 sm:pl-4 px-4 sm:px-6 pb-4 sm:pb-7 flex flex-wrap">
          <div className="min-w-0 w-full sm:w-auto mb-3 sm:mb-0 md:px-2 lg:px-0 xl:col-span-6">
            <div className="flex sm:px-1 sm:py-1 w-full md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
              <div className="w-full">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <input
                    id="search"
                    name="search"
                    className="block w-full sm:w-[345px] sm:w-80 dark:bg-transparent bg-white border border-gray-300 rounded-md sm:pl-4 sm:pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-primary  sm:text-sm"
                    placeholder="Search menu"
                    type="search"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              location.pathname.includes("restaurant")
                ? "flex w-full sm:w-auto"
                : "hidden"
            }
          >
            <div className="lg:border-r-2 sm:mx-8"></div>
            {/* <Tab.List className="overflow-x-auto flex"> */}
            <Tab.List className="flex overflow-x-scroll sm:overflow-auto sm:flex">
              {data?.length > 0 &&
                data.map((item, i) => (
                  <Tab
                    as="a"
                    key={i}
                    href={`#${item}`}
                    className={({ selected }) =>
                      classNames(
                        "rounded-lg py-2 px-6 sm:px-7 sm:mx-1 text-sm font-medium flex flex-none w-fit items-center text-black",
                        "ring-primary ring-offset-0 ring-offset-primary focus:outline-none focus:ring-none focus:bg-[#FFEAE5]",
                        selected
                          ? "bg-[#FFEAE5] border border-primary text-primary"
                          : "text-black dark:text-[#BCBCBC] hover:bg-primary hover:text-white"
                      )
                    }
                  >
                    {item}
                  </Tab>
                ))}
            </Tab.List>
          </div>
        </div>
      </Tab.Group>
    </div>
  );
}
