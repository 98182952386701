import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import { copyTextToClipboard } from "../../../components/helpers";
import EmptyState from "../../../assets/images/emptyState/user.svg";
import { ReactComponent as Code } from "../../../assets/images/icons/get-code.svg";
import { ReactComponent as Invite } from "../../../assets/images/icons/refer-friend.svg";
import { ReactComponent as Reward } from "../../../assets/images/icons/get-reward.svg";
import { getMyReferralsAction } from "../../../redux/referral/referralActions";

export default function MyReferrals() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { myReferrals, isLoading } = useSelector((state) => state.referral);
  const inputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getMyReferralsAction());
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((referral, index) => {
        let show = {
          id: index + 1,
          referral: referral?.referral_code,
          referee: referral?.referrer_code,
          created: moment(referral?.created_at).format("MMM DD, YYYY/ HH:MMa"),
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(myReferrals?.referents);
    setData(dataSet);
  }, [myReferrals?.referents]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Referral Code",
            accessor: "show.referral",
          },
          {
            Header: "Referee Code",
            accessor: "show.referee",
          },
          {
            Header: "Date",
            accessor: "show.created",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 bg-white dark:bg-[#121212] sm:min-h-[1000px] rounded-sm shadow-lg">
        <div className="border-b-2 py-4 sm:py-6 mb-6 block">
          <p className="text-base font-medium text-gray-800 dark:text-white text-center">
            My Referrals
          </p>
        </div>
        <div className="flex gap-8 justify-center items-center mx-auto">
          <div className="relative w-full">
            <div className="text-center mb-20">
              <h2 className="text-2xl font-bold mb-2">
                Ogwugo Referral Program
              </h2>
              <p className="text-lg">
                Give a discount to your friends and get more.{" "}
                <span className="font-medium">How it works:</span>
              </p>
            </div>
            <div className="mb-10">
              <ul className="grid grid-cols-1 md:grid-cols-3 gap-12 sm:gap-10">
                <li className="mx-auto text-center">
                  <Code className="max-h-44 mx-auto" />
                  <h3 className="text-xl font-bold mb-2 mt-7">
                    Get your referral code
                  </h3>
                  <p className="text-sm">Quickly copy your referral code</p>
                </li>
                <li className="mx-auto text-center">
                  <Invite className="max-h-44 mx-auto" />
                  <h3 className="text-xl font-bold mb-2 mt-7">
                    Invite friends
                  </h3>
                  <p className="text-sm">
                    Invite your friends to register through your referral code
                    and get rewards once they complete a successful order with
                    <span className="text-primary ml-1">Ogwugo.</span>
                  </p>
                </li>
                <li className="mx-auto text-center">
                  <Reward className="max-h-44 mx-auto" />
                  <h3 className="text-xl font-bold mb-2 mt-7">Get Rewards</h3>
                  <p className="text-sm">
                    Receive discount in real time via our referral points
                    system.
                  </p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-center gap-6 rounded-md border border-[#E4E4F3] px-3 sm:px-5 py-4 my-2">
              <div className="w-full sm:w-auto">
                <label className="text-primary mb-3 block">
                  Your Personal Referral Code:
                </label>
                <div className="flex">
                  <input
                    ref={inputRef}
                    value={myReferrals?.referral_code}
                    className="block text-xl font-medium bg-[#EFEFEF] dark:bg-[#121212] px-3 py-3 h-[48px] rounded-tl rounded-bl"
                  />
                  <Button.Primary
                    title={"copy"}
                    onClick={() =>
                      copyTextToClipboard(myReferrals?.referral_code)
                    }
                    className={"h-[48px]"}
                  />
                </div>
              </div>
              <div className="flex justify-between w-full sm:w-auto gap-6">
                <div className="text-center flex-none">
                  <h2 className="text-4xl font-bold mb-1">
                    {myReferrals?.referral_count}
                  </h2>
                  <span className="text-primary text-sm font-bold">
                    Total
                    <br />
                    Referrals
                  </span>
                </div>
                <div className="text-center flex-none">
                  <h2 className="text-4xl font-bold mb-1">
                    {myReferrals?.referral_point}
                  </h2>
                  <span className="text-[#9CA3AF] text-sm font-medium">
                    Estimated
                    <br /> Referrals Points
                  </span>
                </div>
              </div>
            </div>

            <div className="rounded-md border border-[#E4E4F3] py-4 my-5">
              <div className="border-b border-[#E4E4F3] pb-3 pt-2 px-5">
                <h3 className="font-medium text-[#4D4D4D] dark:text-white">
                  Referrals
                </h3>
              </div>
              {isLoading ? (
                <TableSkeleton />
              ) : data?.length > 0 ? (
                <Table
                  columns={columns}
                  data={data?.length > 0 ? data : []}
                  className="w-full"
                  onClick={() => {}}
                />
              ) : (
                <div className="py-12 text-center">
                  <div className="flex justify-center">
                    <img
                      src={EmptyState}
                      className="w-auto max-w-[180px] max-h-[180px]"
                      alt="no data"
                    />
                  </div>
                  <h3 className="mt-5 text-xl font-bold">Referrals</h3>
                  <p className="mt-2 text-sm text-[#9CA3AF]">
                    You do not have any referrals yet.
                  </p>
                </div>
              )}
            </div>
            <Button.Secondary
              type="button"
              title={"Back"}
              className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full"}
              onClick={() => navigate("/profile")}
            />
          </div>
        </div>
      </div>
    </>
  );
}
