import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import { deleteAttributeAction } from "../../../redux/attribute/attributeActions";
import { getAllAttributesAction } from "../../../redux/attribute/attributeActions";

export default function DeleteAttribute({ id }) {
  const attribute_id = id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const { isLoading } = useSelector((state) => state.attribute);

  function handleClose() {
    setIsOpen(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await dispatch(
      deleteAttributeAction({ id: attribute_id })
    );
    if (response) {
      dispatch(getAllAttributesAction());
      navigate(-1);
      handleClose();
    }
  };
  return (
    <>
      <Button.Secondary
        className="rounded-sm mr-4 cursor-pointer"
        title={"Remove Attribute"}
        onClick={() => setIsOpen(true)}
      />
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-white dark:bg-[#121212] w-[358px] rounded-sm sm:w-[450px] p-6 sm:p-6 ">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                        <button
                          type="button"
                          className=" "
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close</span>
                          <RiCloseLine />
                        </button>
                      </div>
                      <div>
                        <div className=" text-center  ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black dark:text-white"
                          >
                            Remove Attribute
                          </Dialog.Title>
                        </div>
                      </div>

                      <div className="mt-5 sm:mt-7 text-center">
                        <p className="text-lg px-4 text-[#344B67] dark:text-[#BCBCBC]">
                          Are you sure you want to remove this attribute?
                        </p>
                      </div>
                      <div className="flex justify-between mt-10 sm:mt-10 mb-6">
                        <Button.Secondary
                          title={"Back"}
                          className={"rounded-md px-4 h-14 w-28"}
                          onClick={handleClose}
                        />
                        <button
                          type="submit"
                          className="h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        >
                          {isLoading ? <Beat /> : "Remove"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
