import { useEffect, useState } from "react";
import { SelectableInputModal } from "../modals/admin/selectableInputModal";
import Close from "../../assets/images/icons/close.svg";

const SelectableInput = ({
  options,
  name,
  selectOption,
  previousSelected,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);

  useEffect(() => {
    setOptionsArray(options);
  }, [options]);

  useEffect(() => {
    if (previousSelected) {
      setSelectedVal(previousSelected);
    }
  }, [previousSelected]);

  const handleClick = () => {
    setIsOpen(true);
  };
  const removeItem = (index, value) => {
    let arr = [...selectedVal];
    const filtered = arr.filter((item, i) => i !== index);
    setSelectedVal(filtered);
    selectOption(value);
  };

  return (
    <>
      <button
        type="button"
        className="w-full border border-dashed border-[#C2C9D1] flex flex-wrap gap-3 text-center justify-center p-3 mb-1"
        onClick={handleClick}
      >
        {selectedVal?.length > 0 ? (
          <>
            {selectedVal.map((val, i) => (
              <span
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  removeItem(i, val);
                }}
                className="px-3 text-[16px] dark:text-[#121212] bg-[#F4F4F4] px-2 py-1 rounded-md inline-flex gap-2 items-center"
              >
                {val}
                <img src={Close} alt="close" className="w-[13px] h-[13px]" />
              </span>
            ))}
            <span className="text-[#D2D2D2]">{name}</span>
          </>
        ) : (
          name
        )}
      </button>

      <SelectableInputModal
        title={name}
        type={type}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={optionsArray}
        selectOption={selectOption}
        selectedVal={selectedVal}
        setSelectedVal={setSelectedVal}
      />
    </>
  );
};

export default SelectableInput;
