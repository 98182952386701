import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import CLOSE from "../../../../assets/images/icons/arrow-left.svg";
import Button from "../../../buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import Input from "../../../inputs/inputs";
import Location from "../../../includes/location";
import { getUserFromCookies } from "../../../../utils/Auth";
import { createRestaurantAction } from "../../../../redux/restaurant/restaurantActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";

export default function FormSection5({ formWizardRef, props }) {
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  let business_id = Object.keys(user?.groups)[0];
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading } = useSelector((state) => state.restaurant);
  const [submitted, setSubmitted] = useState(false);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [data, setData] = useState({
    restaurant_email: "",
    restaurant_name: "",
    restaurant_address: "",
    restaurant_city: "",
    restaurant_state: "",
    restaurant_country: "",
    restaurant_categories: [],
    restaurant_description: "",
  });
  const {
    restaurant_name,
    restaurant_email,
    restaurant_address,
    restaurant_city,
    restaurant_state,
    restaurant_country,
    restaurant_description,
    restaurant_categories,
  } = data;

  let [restaurant_phone, setRestaurantPhone] = useState("");
  restaurant_phone = restaurant_phone.slice(1);

  const restaurantCategories = allCategories?.data?.filter((item) => {
    return item?.type === "restaurant";
  });

  useEffect(() => {
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "restaurant_categories") {
      setData({ ...data, restaurant_categories: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (
      restaurant_name &&
      restaurant_phone &&
      restaurant_address &&
      restaurant_categories &&
      restaurant_city &&
      restaurant_state &&
      restaurant_country
    ) {
      // dispatch action to create data
      const response = await dispatch(
        createRestaurantAction({
          ...data,
          restaurant_phone,
          business_id: business_id,
        })
      );
      if (response.status === "success") {
        formWizardRef.current.goTo(4);
      }
    }
  };
  return (
    <Dialog.Panel className="relative sm:w-[708px] h-[877px] bg-white dark:bg-[#010101] rounded-3xl px-4 pt-5 pb-4 text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8  w-full sm:p-8">
      <div className=" sm:block absolute top-2 left-8 pt-4 pr-4">
        <button
          type="button"
          className="bg-[#ffeae5] rounded-md p-2 sm:p-3 "
          onClick={() => formWizardRef.current.goTo(4)}
        >
          <span className="sr-only">Close</span>
          <img src={CLOSE} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>

      <div className="sm:mt-10 text-left">
        <form name="form" className="border">
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
              Restaurant Details
            </p>
          </div>

          <div className=" sm:px-10">
            <div
              className={
                "mt-4" +
                (submitted && !restaurant_name ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Restaurant Name "}
                htmlFor={"restaurant_name"}
                type={"text"}
                name={"restaurant_name"}
                onChange={handleChange}
                placeholder={"Enter restaurant name"}
              />
              {submitted && !restaurant_name && (
                <div className="text-xs text-red-500">
                  Restaurant name is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" +
                (submitted && !restaurant_email ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Restaurant Email *"}
                htmlFor={"restaurant_email"}
                type={"email"}
                name={"restaurant_email"}
                onChange={handleChange}
                placeholder={"Enter restaurant email address"}
              />
              {submitted && !restaurant_email && (
                <div className="text-xs text-red-500">
                  Restaurant email is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" +
                (submitted && !restaurant_phone ? " border-red-500" : "")
              }
            >
              <Input.Phone
                defaultCountry="NG"
                title={"Restaurant Phone Number*"}
                htmlFor={"restaurant_phone"}
                value={restaurant_phone}
                onChange={setRestaurantPhone}
              />
              {submitted && !restaurant_phone && (
                <div className="text-xs text-red-500">
                  Restaurant phone is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" +
                (submitted && !restaurant_address ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Restaurant Address *"}
                htmlFor={"restaurant_address"}
                type={"text"}
                name={"restaurant_address"}
                onChange={handleChange}
                placeholder={"Enter Restaurant Address"}
              />
              {submitted && !restaurant_address && (
                <div className="text-xs text-red-500">
                  Restaurant address is required
                </div>
              )}
            </div>

            <div
              className={
                "mt-4" +
                (submitted && restaurant_description ? " border-red-500" : "")
              }
            >
              <Input.TextArea
                title={"Restaurant Description "}
                htmlFor={"restaurant_description"}
                type={"text"}
                name={"restaurant_description"}
                onChange={handleChange}
                placeholder={"Describe your restaurant to us"}
              />
            </div>

            <div
              className={
                submitted && !restaurant_categories ? "  border-red-500" : ""
              }
            >
              <div className="mt-2">
                <Input.Select
                  title={"Restaurant Category*"}
                  htmlFor={"restaurant_categories"}
                  name={"restaurant_categories"}
                  id={"restaurant_categories"}
                  onChange={handleChange}
                  className={
                    "mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  }
                >
                  <option>Select a restaurant category</option>
                  {restaurantCategories?.map((category, i) => (
                    <option key={i} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Input.Select>

                {submitted && !restaurant_categories && (
                  <div className="text-primary text-xs ">
                    Restaurant category is required
                  </div>
                )}
              </div>
            </div>

            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              loadingLocation={(value) => {
                setLocationLoader(value);
              }}
              countryList={(value) => {
                setLocationList({ ...locationList, countryList: value });
              }}
              stateList={(value) => {
                setLocationList({ ...locationList, stateList: value });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div
              className={
                "mt-4" +
                (submitted && !restaurant_country ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="restaurant_country"
                className="text-sm font-medium text-[#151515] dark:text-white"
              >
                Country*
              </label>
              <select
                name="restaurant_country"
                className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFFFF] dark:bg-transparent focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select Country</option>
                {locationLoader ? (
                  <option>Loading...</option>
                ) : (
                  locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })
                )}
              </select>

              {submitted && !restaurant_country && (
                <div className="text-xs text-red-500">
                  Restaurant country is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" +
                (submitted && !restaurant_state ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="restaurant_state"
                className="text-sm font-medium text-[#151515] dark:text-white"
              >
                {" "}
                State{" "}
              </label>
              <select
                name="restaurant_state"
                className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFFFF] dark:bg-transparent focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select state</option>

                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !restaurant_state && (
                <div className="text-xs text-red-500">
                  Restaurant state is required.
                </div>
              )}
            </div>
            <div
              className={
                "mt-4 mb-16" +
                (submitted && !restaurant_city ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="restaurant_city"
                className="text-sm font-medium text-[#151515] dark:text-white"
              >
                {" "}
                City*{" "}
              </label>
              <select
                name="restaurant_city"
                className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFFFF] dark:bg-transparent focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] focus:outline-none px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select a city</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !restaurant_city && (
                <div className="text-xs text-red-500">
                  Restaurant city is required
                </div>
              )}
            </div>
          </div>
        </form>
        <Button.Primary
          title={isLoading ? <Beat color={"#ffffff"} /> : "Submit"}
          className={"w-[333px] sm:mt-10 mx-auto flex justify-center"}
          onClick={handleSubmit}
        />
      </div>
    </Dialog.Panel>
  );
}
