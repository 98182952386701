import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddressDropDown from "../../../components/dropDown/addressDropDown";
import Button from "../../../components/buttons/buttons";
import { getMyAddressesAction } from "../../../redux/address/addressActions";
import { AddNewAddressModal } from "../../../components/modals/address/addNewAddressModal";

export default function MyAddresses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const { myAddresses } = useSelector((state) => state.address);

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getMyAddressesAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAddresses(myAddresses);
  }, [myAddresses]);

  return (
    <>
      <AddNewAddressModal show={isOpen} onClose={closeModal} />
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0  px-6 bg-white dark:bg-[#121212] sm:min-h-[1000px] rounded-sm shadow-2xl">
        <div className="border-b-2 py-4 sm:py-6 mb-6 block">
          <p className="text-base dark:text-white font-medium text-gray-800 text-center">
            Addresses
          </p>
        </div>
        <div className=" flex gap-8 justify-center items-center mx-auto">
          <div className="max-w-2xl w-full sm:w-[800px] relative ">
            <ul>
              {addresses?.data?.length > 0 &&
                addresses?.data?.map((address, index) => (
                  <li
                    key={index}
                    className="py-3 sm:pb-2 sm:py-2 mb-4 border dark:border-[#BCBCBC] rounded-lg h-20 shadow-lg"
                  >
                    <div className="flex items-center justify-between gap-4 px-3 sm:px-4">
                      <div className="flex py-2 truncate">
                        <div className="sm:ml-3">
                          <div className="flex ">
                            <p className="text-lg text-start font-medium text-gray-900 dark:text-white truncate">
                              {address?.title}
                            </p>
                            {address?.address_selected === true && (
                              <div className="bg-[#FFCFD0] px-2 py-1 rounded-md h-6 ml-2">
                                <p className="text-primary text-[12px] font-medium">
                                  Default
                                </p>
                              </div>
                            )}
                          </div>

                          <p className="text-sm text-gray-500 dark:text-[#BCBCBC] truncate pb-4">
                            {address?.address}, {address?.country}
                          </p>
                        </div>
                      </div>
                      <AddressDropDown address={address} />
                    </div>
                  </li>
                ))}
            </ul>

            <Button.Primary
              className="text-left mb-10 mt-6 sm:mt-20 text-white rounded-md w-full sm:w-[333px] flex justify-center mx-auto "
              onClick={openModal}
              title={"Add New Address"}
            />
            <Button.Secondary
              type="button"
              title={"Back"}
              className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full"}
              onClick={() => navigate("/profile")}
            />
          </div>
        </div>
      </div>
    </>
  );
}
