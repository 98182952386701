import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Card from "../../../components/cards/cards";
import Container from "../../../components/container/container";
import CatalogueSlider from "../../../components/slider/CatalogueSlider";
import { CatalogueNav } from "../../../components/slider/CatalogueSlider";

export const Catalogs = ({ cat }) => {
  const catalogueRef = useRef(null);
  const { settings } = useSelector((state) => state.application);
  return (
    <Container>
      <div className="py-5 mt-4 sm:mt-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold capitalize">{cat?.catalogue_title}</h3>
          <div className="flex items-center gap-4">
            <div className="block">
              <CatalogueNav catalogueRef={catalogueRef} />
            </div>
          </div>
        </div>

        <div>
          <CatalogueSlider catalogueRef={catalogueRef}>
            {cat?.restaurants &&
              cat?.restaurants?.map((restaurant, i) => (
                <Card.Restaurants
                  id={restaurant?.id}
                  key={i}
                  tags={restaurant?.restaurant_tags}
                  categories={restaurant?.restaurant_categories}
                  title={restaurant?.restaurant_name}
                  image={restaurant?.restaurant_pictures}
                  operating_settings={settings?.restaurant_operating_status}
                  operating_status={restaurant?.restaurant_operating_status}
                  operating_hours={restaurant?.restaurant_operating_time}
                />
              ))}
            {cat?.stores &&
              cat?.stores?.map((store, i) => (
                <Card.Store
                  id={store?.id}
                  key={i}
                  tags={store?.store_tags}
                  categories={store?.store_categories}
                  title={store?.store_name}
                  image={store?.store_pictures}
                  operating_settings={settings?.store_operating_status}
                  operating_status={store?.store_operating_status}
                  operating_hours={store?.store_operating_time}
                />
              ))}
            {cat?.foods &&
              cat?.foods?.map((food, i) => (
                <Card.MenuRestaurant
                  key={i}
                  image={food?.food_pictures}
                  title={food?.food_name}
                  amount={food?.food_amount}
                  tags={food?.food_tags}
                  id={food?.restaurant_id}
                />
              ))}
            {cat?.merchandise &&
              cat?.merchandise?.map((merchandise, i) => (
                <Card.Menu
                  key={i}
                  image={merchandise?.merchandise_pictures}
                  title={merchandise?.merchandise_name}
                  amount={merchandise?.merchandise_amount}
                  tags={merchandise?.merchandise_tags}
                  id={merchandise?.store_id}
                />
              ))}
          </CatalogueSlider>
        </div>
      </div>
    </Container>
  );
};
