import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import Cookies from "js-cookie";
import { history } from "../../../../helpers/history";
import Button from "../../../buttons/buttons";
import { ReactComponent as Cutlery } from "../../../../assets/images/icons/cutlery.svg";
import EmptyState from "../../../../assets/images/emptyState/restaurant.svg";
// import { getTeamFromCookies } from "../../../../utils/Auth";
import { filterRestaurantsAction } from "../../../../redux/restaurant/restaurantActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ChooseRestaurant({ formWizardRef, team }) {
  // const team = getTeamFromCookies();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});
  const { allRestaurants } = useSelector((state) => state.restaurant);
  useEffect(() => {
    if (team) {
      dispatch(filterRestaurantsAction({ business_id: team }));
    }
    // eslint-disable-next-line
  }, [team]);

  const handleAccounts = (e) => {
    setSelected(e);
    Cookies.set("team", team);
    Cookies.set("outlet", JSON.stringify(e));
    Cookies.set("outlet-type", "restaurant");
    history(`/admin/${e?.restaurant_name}/dashboard`);
  };

  return (
    <RadioGroup value={selected} onChange={(e) => handleAccounts(e)}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="space-y-4 h-full">
        {allRestaurants?.data?.length > 0 ? (
          allRestaurants?.data?.map((restaurant, i) => (
            <RadioGroup.Option
              key={i}
              value={restaurant}
              className={({ checked, active }) =>
                classNames(
                  checked
                    ? "border-transparent"
                    : "border-gray-300 shadow-[0_3px_8px_rgba(74, 85, 104, 0.2)] shadow-md outline-0",
                  active ? "border-primary ring-2 ring-primary outline-0" : "",
                  "block bg-white dark:bg-darkBg border rounded-lg px-4 sm:px-6 py-5 sm:py-7 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex gap-4 items-center w-full">
                    <Cutlery className="w-10 sm:w-12 h-10 sm:h-12" />
                    <div className="text-sm w-full">
                      <div className="flex justify-between items-center gap-2">
                        <RadioGroup.Label
                          as="span"
                          className="font-medium text-2xl"
                        >
                          {restaurant?.restaurant_name}
                        </RadioGroup.Label>
                        <span className="bg-[#FBEDC5] text-sm font-medium text-[#333333] px-2">
                          {restaurant?.id}
                        </span>
                      </div>

                      <RadioGroup.Description
                        as="span"
                        className="text-gray-500 block sm:inline font-md text-lg"
                      >
                        {restaurant?.restaurant_address}
                      </RadioGroup.Description>
                    </div>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))
        ) : (
          <div className="py-28 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Restaurant</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any restaurant yet.
            </p>
          </div>
        )}

        <div
          className={`${
            allRestaurants?.data?.length > 4
              ? "relative mt-4"
              : "absolute bottom-1"
          } w-full p-4 sm:p-4 left-0 right-0 text-center`}
        >
          <Button.Primary
            title={"Create New Restaurant"}
            onClick={() => formWizardRef.current.goTo(5)}
            className="w-full py-4 sm:w-[334px] h-[60px]"
          />
        </div>
      </div>
    </RadioGroup>
  );
}
