import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../components/buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import { cleanInput } from "../../../helpers/cleanInput";
import { PriceNoticeModal } from "../../../components/modals/payment/PriceNotice";
import PaymentGuestCartModal from "../../../components/modals/payment/paymentGuestCartModal";
import { storePublicCartProducts } from "../../../redux/cart/cartAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function OrderSummary({
  myCart,
  setMyCart,
  orderData,
  isContinueClicked,
  setIsContinueClicked,
}) {
  const dispatch = useDispatch();
  const paymentModalRef = useRef(null);
  const [openNotice, setOpenNotice] = useState(false);
  const { processing, storing } = useSelector((state) => state.cart);

  //remove cart product items
  const handleSubmit = () => {
    paymentModalRef.current.open();
  };

  const updateCart = async () => {
    if (myCart) {
      let updateCart = myCart;
      try {
        cleanInput(updateCart);
        const response = await dispatch(
          storePublicCartProducts({
            ...updateCart,
            city: "Enugu",
            state: "Enugu",
            country: "Nigeria",
            service_area_id: "1",
            address:
              orderData?.address !== ""
                ? orderData?.address
                : "Suite D5 Bethel Plaza, 36 Garden Ave, Enugu 400261",
          })
        );
        if (response) {
          setIsContinueClicked(true);
          localStorage.setItem(
            "ogwugo-cart-items",
            JSON.stringify(response?.data)
          );
          localStorage.setItem("ogwugo-cart", JSON.stringify(updateCart));
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  return (
    <div>
      {myCart?.products?.length > 0 ? (
        <div className="rounded-lg bg-white dark:bg-[#121212] sm:max-w-3xl overflow-hidden border px-4 py-5 mb-6 shadow-lg">
          <h3 className="text-xl font-medium mb-6 pb-3 border-b">Summary</h3>

          <div className="mb-5 pt-2">
            <div className="flex justify-between items-center mb-4">
              <label>Subtotal:</label>
              <span>₦{numberWithCommas(myCart?.sub_amount)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Service fee:</label>
              <span>₦{numberWithCommas(myCart?.service_fee)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Delivery fee:</label>
              <span>₦{numberWithCommas(myCart?.delivery_fee)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Insurance fee:</label>
              <span>₦{numberWithCommas(myCart?.insurance_fee)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Discount:</label>
              <span>₦{numberWithCommas(myCart?.discount)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Tax fee:</label>
              <span>₦{numberWithCommas(myCart?.tax_fee)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Handling fee:</label>
              <span>₦{numberWithCommas(myCart?.handling_fee)}</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <label>Total:</label>
              <span className="font-bold">
                ₦{numberWithCommas(myCart?.amount)}
              </span>
            </div>
          </div>

          {isContinueClicked ? (
            <Button.Primary
              disabled={
                processing ||
                !orderData?.name ||
                !orderData?.email ||
                !orderData?.phone
              }
              title={processing ? <Beat /> : "Pay Now"}
              className={"w-full h-[48px] px-4 mt-6 mb-2"}
              onClick={() => handleSubmit()}
            />
          ) : (
            <Button.Primary
              disabled={
                storing ||
                !orderData?.name ||
                !orderData?.email ||
                !orderData?.phone
              }
              title={storing ? <Beat /> : "Continue"}
              className={"w-full h-[48px] px-4 mt-6 mb-2"}
              onClick={() => updateCart()}
            />
          )}
        </div>
      ) : (
        <div className="rounded-lg bg-white sm:max-w-3xl overflow-hidden border px-4 py-5 mb-6 shadow-lg">
          <h3 className="text-xl font-medium mb-6 pb-3 border-b">Summary</h3>
          <p>You have not added any item for checkout.</p>
        </div>
      )}

      <p className="text-sm text-[#333333] dark:text-[#BCBCBC] font-bold">
        Final prices are subject to change...{" "}
        <span
          className="text-primary cursor-pointer"
          onClick={() => setOpenNotice(true)}
        >
          Learn more
        </span>
      </p>

      <PaymentGuestCartModal
        ref={paymentModalRef}
        payment_type={"standard"}
        visibility={false}
        orderData={orderData}
      />
      <PriceNoticeModal open={openNotice} setOpen={setOpenNotice} />
    </div>
  );
}
