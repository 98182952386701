import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditStoreAttribute from "./../../../../../components/modals/admin/editStoreAttribute";
import DeleteAttribute from "../../../../../components/modals/admin/deleteAttribute";
import { showSingleAttributeAction } from "../../../../../redux/attribute/attributeActions";
import Table from "../../../../../components/tables/tableCols1";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function SingleStoreAttribute() {
  const dispatch = useDispatch();
  const { attribute } = useSelector((state) => state.attribute);
  const { id } = useParams();

  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(showSingleAttributeAction(id));
  }, [dispatch, id]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((food, index) => {
        let single = {
          num: <span className="text-base font-semibold">{food?.id}</span>,
          name: food.merchandise_name,
          created: (
            <span>{moment(food?.created_at).format("MMM DD, YYYY")}</span>
          ),
          value: food.merchandise_amount,

          attribute: attribute,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(attribute?.merchandise);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);


  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        columns: [
          {
            Header: "Merchandise ID",
            accessor: "single.num",
          },

          {
            Header: "Name",
            accessor: "single.name",
          },
          {
            Header: "Amount",
            accessor: "single.value",
          },
          {
            Header: "Created At",
            accessor: "single.created",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">Attribute Details</h3>
          <div>
            <DeleteAttribute id={id} />
            <EditStoreAttribute id={id} myAttributes={attribute} />
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Title</label>
            <p className="text-[#151515] dark:text-white text-sm">{attribute?.title}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">{attribute?.name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Value</label>
            <p className="text-[#151515] dark:text-white text-sm">{attribute?.amount}</p>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
        <Table
          columns={columns}
          data={data?.length > 0 ? data : []}
          className="w-full"
          rowClass="hover:shadow-md cursor-pointer"
        />
      </div>
    </div>
  );
}
