import React, { useState, useEffect } from "react";

export default function Display() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    // Add or remove the 'dark' class based on the theme
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    // Save theme preference in localStorage
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = (theme) => {
    setTheme(theme);
  };

  const [selectedOption, setSelectedOption] = useState(""); // Track the selected option

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value); // Update selected option when a radio button is clicked
  };

  const handleSubmit = () => {
    toggleTheme(selectedOption);
  };

  return (
    <div data-test="resetComponent">
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 pb-10 bg-white dark:bg-[#121212] sm:min-h-[1000px] rounded-sm shadow-lg">
        <div className="border-b-2 py-4 sm:py-6 mb-6 flex sm:block justify-between items-center">
          <p className="text-base font-medium text-gray-800 dark:text-white text-center">
            Display
          </p>
        </div>

        <div className="justify-center items-center mx-auto max-w-[535px]">
          <div className="mt-2 flex items-center justify-between gap-3 space-y-2">
            {/* Option 1 */}
            <label
              className="flex items-center gap-5 w-full bg-white rounded-[1rem] h-[96px] px-4"
              style={{ boxShadow: "18px 18px 100px 0px #7090b026" }}
            >
              <input
                type="radio"
                value="light"
                checked={selectedOption === "light"}
                onChange={handleOptionChange}
                className="form-radio text-blue-600"
              />

              <div>
                <span className="block text-[14px] text-[#344B67]">
                  Background
                </span>
                <p className="text-[#151515] text-[1.5rem]">Default</p>
              </div>
            </label>

            {/* Option 2 */}
            <label
              className="flex items-center gap-5 w-full bg-[#010101] rounded-[1rem] h-[96px] px-4"
              style={{ boxShadow: "18px 18px 100px 0px #7090b026" }}
            >
              <input
                type="radio"
                value="dark"
                checked={selectedOption === "dark"}
                onChange={handleOptionChange}
                className="form-radio text-blue-600"
              />
              <div>
                <span className="block text-[14px] text-[#BCBCBC]">
                  Background
                </span>
                <p className="text-[#ffffff] text-[1.5rem]">Dark Theme</p>
              </div>
            </label>
          </div>

          <div className="mt-10">
            <button
              onClick={() => {
                handleSubmit();
              }}
              className=" mt-5 w-full inline-flex justify-center h-12 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
            >
              Apply Display
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
