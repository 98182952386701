import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import { getUserFromCookies } from "../../../utils/Auth";
import Location from "../../../components/includes/location";
import { history } from "../../../helpers/history";
import { createAStore } from "./../../../redux/stores/storeActions";
import SelectableInput from "../../../components/inputs/SelectableInput";
import { getAllTags } from "../../../redux/productTags/productTagActions";
import { filterProductCategory } from "./../../../redux/productCatergory/productCategoryAction";
import { logoutUserLocallyAction } from "../../../plugins/axios.plugin";

export default function CreateStore() {
  const user = getUserFromCookies();
  let business_id = Object.keys(user.groups)?.[0];
  const dispatch = useDispatch();

  const [locationLoader, setLocationLoader] = useState(false);
  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);

  useEffect(() => {
    dispatch(getAllTags(1));
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });
  const storeTags = allTags?.data?.filter((item) => {
    return item?.type === "store";
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    store_email: "",
    store_name: "",
    store_address: "",
    store_city: "",
    store_state: "",
    store_country: "",
    store_description: "",
    store_categories: [],
    store_tags: [],
  });
  const {
    store_name,
    store_email,
    store_address,
    store_city,
    store_state,
    store_country,
    store_description,
    store_categories,
    store_tags,
  } = data;

  let [store_phone, setStorePhone] = useState("");
  store_phone = store_phone?.slice(1);

  const handleAddTags = (value) => {
    const former_tags = [...data?.store_tags];
    let new_tags = [...former_tags, value.name];
    setData({ ...data, store_tags: new_tags });
  };

  const handleAddCategories = (value) => {
    const former_categories = [...data?.store_categories];
    let new_categories = [...former_categories, value.name];
    setData({ ...data, store_categories: new_categories });
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    if (name === "store_categories") {
      setData({ ...data, store_categories: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      store_name &&
      store_phone &&
      store_address &&
      store_categories &&
      store_city &&
      store_state &&
      store_country
    ) {
      // dispatch action to create data
      const response = await dispatch(
        createAStore({ ...data, store_phone, business_id: business_id })
      );
      if (response.status === "success") {
        logoutUserLocallyAction();
        history("/success");
      }
    }
  };

  return (
    <div>
      <div
        className="pt-4 sm:pt-2 sm:flex items-center mx-auto"
        data-test="createStoreComponent"
      >
        <div className=" max-w-4xl mx-auto">
          <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
            <form name="form" id="store_form" onSubmit={handleSubmit}>
              <div className="border-b-2 py-4 mb-6">
                <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
                  Store Details
                </p>
              </div>

              <div className=" sm:px-10">
                <div
                  className={
                    "mt-4" + (submitted && store_name ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Name"}
                    htmlFor={"store_name"}
                    type={"text"}
                    name={"store_name"}
                    onChange={handleChange}
                    placeholder={"Enter store name"}
                  />
                  {submitted && !store_name && (
                    <div className="text-xs text-red-500">
                      Store name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" + (submitted && store_email ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Email *"}
                    htmlFor={"store_email"}
                    type={"email"}
                    name={"store_email"}
                    onChange={handleChange}
                    placeholder={"Enter store email address"}
                  />
                </div>
                <div
                  className={
                    "mt-4" + (submitted && store_phone ? " border-red-500" : "")
                  }
                >
                  <Input.Phone
                    defaultCountry="NG"
                    title={"Store Phone Number"}
                    htmlFor={"store_phone"}
                    value={store_phone}
                    onChange={setStorePhone}
                  />
                  {submitted && !store_phone && (
                    <div className="text-xs text-red-500">
                      Store phone is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && store_address ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Address *"}
                    htmlFor={"store_address"}
                    type={"text"}
                    name={"store_address"}
                    onChange={handleChange}
                    placeholder={"Enter store Address"}
                  />
                </div>
                {submitted && !store_address && (
                  <div className="text-xs text-red-500">
                    Store address is required
                  </div>
                )}
                <div
                  className={
                    "mt-4" +
                    (submitted && store_description ? " border-red-500" : "")
                  }
                >
                  <Input.TextArea
                    title={"Store Description "}
                    htmlFor={"store_description"}
                    type={"text"}
                    name={"store_description"}
                    onChange={handleChange}
                    placeholder={"Describe your store to us"}
                  />
                </div>
                {/* <div
                  className={
                    submitted && !store_categories ? "  border-red-500" : ""
                  }
                >
                  <label
                    htmlFor="store_categories"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Store Category*
                  </label>
                  <div className="mt-2 ">
                    <select
                      name="store_categories"
                      className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-transparent focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                      onChange={handleChange}
                    >
                      <option>Select a store category</option>
                      {categories()}
                    </select>
                    {submitted && !store_categories && (
                      <div className="text-primary text-xs ">
                        Store category is required
                      </div>
                    )}
                  </div>
                </div> */}
                <div
                  className={
                    "mt-4" +
                    (submitted && store_categories ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_categories"
                    className="text-sm font-medium text-[#151515] dark:text-white"
                  >
                    Store Categories*
                  </label>
                  <SelectableInput
                    options={storeCategories}
                    label="store_categories"
                    name={"+ Add Category"}
                    type="category"
                    selectOption={handleAddCategories}
                  />
                </div>

                <div
                  className={
                    "mt-4" + (submitted && store_tags ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_tags"
                    className="text-sm font-medium text-[#151515] dark:text-white"
                  >
                    Store Tags*
                  </label>
                  <SelectableInput
                    options={storeTags}
                    label="store_tags"
                    name={"+ Add Tag"}
                    type="tag"
                    selectOption={handleAddTags}
                  />
                </div>
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  loadingLocation={(value) => {
                    setLocationLoader(value);
                  }}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                />
                <div
                  className={
                    "mt-4" +
                    (submitted && store_country ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_country"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Country*
                  </label>
                  <select
                    name="store_country"
                    className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF] dark:bg-transparent focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select Country</option>

                    {locationLoader ? (
                      <option>Loading...</option>
                    ) : (
                      locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    )}
                  </select>

                  {submitted && !store_country && (
                    <div className="text-xs text-red-500">
                      Store country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" + (submitted && store_state ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_state"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {" "}
                    State{" "}
                  </label>
                  <select
                    name="store_state"
                    className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF] dark:bg-transparent focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select state</option>

                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !store_state && (
                    <div className="text-xs text-red-500">
                      Store state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4 mb-16" +
                    (submitted && store_city ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_city"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {" "}
                    City*{" "}
                  </label>
                  <select
                    name="store_city"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFFF] dark:bg-transparent focus:outline-none  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select a city</option>
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !store_city && (
                    <div className="text-xs text-red-500">
                      Store city is required
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>

          <Button.Primary
            type={"submit"}
            form={"store_form"}
            title={"Submit"}
            className={"w-[333px] sm:mt-10 mx-auto flex justify-center  "}
          />
        </div>
      </div>
    </div>
  );
}
