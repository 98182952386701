import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import Container from "../../../components/container/container";
import { LeftColumn, RightColumn } from "../../../layouts/RestaurantLayout";
import Placeholder from "../../../assets/images/pictures/pfm-banner.jpg";
import Paid from "../../../assets/images/pictures/pfm-paid.svg";
import ErrorBg from "../../../assets/images/pictures/error404-bg.png";
import Image404 from "../../../assets/images/icons/error-img.svg";
import { payForMe } from "../../../redux/payment/paymentActions";
import PaymentSkeleton from "../../../components/skeleton/payment";
import PaymentModal2 from "../../../components/modals/payment/paymentModal2";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function PayForMe() {
  const dispatch = useDispatch();
  let { id } = useParams();
  const paymentModalRef = React.useRef(null);
  const [receipt_email, setReceiptEmail] = useState("");
  const { restaurant } = useSelector((state) => state.publicRestaurant);
  const { pay_for_me, isLoading } = useSelector((state) => state.payment);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getPfm() {
    try {
      await dispatch(payForMe({ pfm: id }));
    } catch (error) {}
  }
  useEffect(() => {
    getPfm();
    // eslint-disable-next-line
  }, []);

  function getTotalOrderItems(orders) {
    let totalOrderItems = 0;
    for (let i = 0; i < orders?.length; i++) {
      totalOrderItems += orders[i]?.order_items?.length;
    }
    return totalOrderItems;
  }

  const item_number =
    getTotalOrderItems(pay_for_me?.data?.restaurant_order) +
    getTotalOrderItems(pay_for_me?.data?.store_order) +
    getTotalOrderItems(pay_for_me?.data?.subscription_food_order) +
    getTotalOrderItems(pay_for_me?.data?.subscription_merchandise_order) +
    Number(pay_for_me?.data?.featured?.length);

  function getTotalItemPrice(product, price) {
    let itemPrice = Number(price) * Number(product.quantity);
    let attributePrice = 0;
    for (let i = 0; i < product?.product_attributes?.length; i++) {
      attributePrice +=
        Number(product?.product_attributes[i]?.amount) *
        Number(product?.product_attributes[i]?.quantity);
    }
    return numberWithCommas(Number(attributePrice) + Number(itemPrice));
  }

  return (
    <div>
      <div
        className="min-h-96 bg-cover no-repeat bg-center relative z-0 dark-overlay"
        style={{
          backgroundImage: `url('${
            restaurant?.restaurant_banners?.[0]
              ? restaurant?.restaurant_banners?.[0]
              : Placeholder
          }')`,
        }}
      >
        <Container>
          <div className="py-24 text-center">
            <h2
              className="text-5xl sm:text-7xl text-white font-bold mb-3 mt-16"
              style={{ lineHeight: "64px" }}
            >
              Pay For Me!
            </h2>
            {pay_for_me && (
              <p className="text-white text-2xl">
                {item_number} Item
                {item_number > 1 ? "s" : ""} only! For{" "}
                {pay_for_me?.data?.user?.name ?? "your friend"}’s Take Away!
              </p>
            )}
          </div>
        </Container>
      </div>

      <Container>
        <main className="-mt-12 pb-12 relative z-10">
          <div className="sm:rounded-md sm:max-w-7xl bg-white dark:bg-[#121212] border dark:border-none shadow-lg pt-5 sm:pt-7 pb-4 px-4 sm:px-6 sm:pb-10">
            <div className="text-center text-[18px] sm:text-2xl py-2">
              “You are paying for{" "}
              {pay_for_me?.data?.user?.name ?? "your friend"}’s purchase (your
              name and email address will be shared with{" "}
              {pay_for_me?.data?.user?.name ?? "your friend"})”
            </div>
          </div>
        </main>
      </Container>

      <Container>
        {pay_for_me?.data?.paid === true && (
          <div className="mt-4 text-center justify-center">
            <img src={Paid} className="mb-4 mx-auto h-[450px]" alt="pfm-paid" />
            <h2 className="text-[#FFD24D] text-3xl font-bold mt-5 mb-1">
              Item’s Already Paid For!
            </h2>
            <p className="dark:text-[#BCBCBC]">
              “You are such an amazing friend, you are the real MVP.”{" "}
            </p>
          </div>
        )}

        {pay_for_me?.data?.paid === false && (
          <div className="grid grid-cols-1 gap-6 items-start lg:grid-cols-5 lg:gap-4">
            <LeftColumn>
              <section aria-labelledby="section-1-title">
                <h2 className="sr-only" id="section-1-title">
                  Restaurant Menu
                </h2>
                {isLoading ? (
                  <PaymentSkeleton />
                ) : (
                  <table className="w-full">
                    {pay_for_me?.data?.restaurant_order?.length > 0 &&
                      pay_for_me?.data?.restaurant_order.map((order) =>
                        order.order_items.map((product, i) => (
                          <>
                            <thead>
                              <tr>
                                <td className="py-2">PRODUCT</td>
                                <td className="py-2">PRICE</td>
                                <td className="py-2 text-center">QTY</td>
                                <td className="py-2 text-center">Total</td>
                              </tr>
                            </thead>
                            <tr key={i}>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                <div className="text-left">
                                  <p className="mb-2">
                                    {product?.product_name}
                                  </p>
                                  {product?.product_attributes?.length > 0 &&
                                    product?.product_attributes?.map(
                                      (attr, i) => (
                                        <p key={i} className="text-sm">
                                          {attr.quantity}x {attr?.title} - NGN{" "}
                                          {attr.amount.toFixed(2)}
                                        </p>
                                      )
                                    )}
                                </div>
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                NGN {Number(product?.product_amount).toFixed(2)}
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                <div className="bg-[#F6F7F8] dark:bg-[#121212] text-center">
                                  {product.quantity}
                                </div>
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8] text-center">
                                NGN{" "}
                                {getTotalItemPrice(
                                  product,
                                  product?.product_amount
                                )}
                              </td>
                            </tr>
                          </>
                        ))
                      )}

                    {pay_for_me?.data?.store_order?.length > 0 &&
                      pay_for_me?.data?.store_order.map((order) =>
                        order.order_items.map((product, i) => (
                          <>
                            <thead>
                              <tr>
                                <td className="py-2">PRODUCT</td>
                                <td className="py-2">PRICE</td>
                                <td className="py-2 text-center">QTY</td>
                                <td className="py-2 text-center">Total</td>
                              </tr>
                            </thead>
                            <tr key={i}>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                <div className="text-left">
                                  <p className="mb-2">
                                    {product?.product_name}
                                  </p>
                                  {product?.product_attributes?.length > 0 &&
                                    product?.product_attributes?.map(
                                      (attr, i) => (
                                        <p key={i} className="text-sm">
                                          {attr.quantity}x {attr?.title} - NGN{" "}
                                          {attr.amount.toFixed(2)}
                                        </p>
                                      )
                                    )}
                                </div>
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                NGN{" "}
                                {Number(product?.product_amount.toFixed(2)) /
                                  Number(product.quantity)}
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                <div className="bg-[#F6F7F8] text-center">
                                  {product.quantity}
                                </div>
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8] text-center">
                                NGN{" "}
                                {getTotalItemPrice(
                                  product,
                                  product?.product_amount
                                )}
                              </td>
                            </tr>
                          </>
                        ))
                      )}

                    {pay_for_me?.data?.subscription_food_order?.length > 0 &&
                      pay_for_me?.data?.subscription_food_order.map((order) =>
                        order.order_items.map((product, i) => (
                          <>
                            <thead>
                              <tr>
                                <td className="py-2">PRODUCT</td>
                                <td className="py-2">PRICE</td>
                                <td className="py-2 text-center">QTY</td>
                                <td className="py-2 text-center">Total</td>
                              </tr>
                            </thead>
                            <tr key={i}>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                <div className="text-left">
                                  <p className="mb-2">
                                    {product?.product_name}
                                  </p>
                                  {product?.product_attributes?.length > 0 &&
                                    product?.product_attributes?.map(
                                      (attr, i) => (
                                        <p key={i} className="text-sm">
                                          {attr.quantity}x {attr?.title} - NGN{" "}
                                          {attr.amount.toFixed(2)}
                                        </p>
                                      )
                                    )}
                                </div>
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                NGN{" "}
                                {Number(product?.product_amount.toFixed(2)) /
                                  Number(product.quantity)}
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8]">
                                <div className="bg-[#F6F7F8] text-center">
                                  {product.quantity}
                                </div>
                              </td>
                              <td className="py-3 border-t-2 border-[#F6F7F8] text-center">
                                NGN{" "}
                                {getTotalItemPrice(
                                  product,
                                  product?.product_amount
                                )}
                              </td>
                            </tr>
                          </>
                        ))
                      )}

                    {pay_for_me?.data?.subscription_merchandise_order?.length >
                      0 &&
                      pay_for_me?.data?.subscription_merchandise_order.map(
                        (order) =>
                          order.order_items.map((product, i) => (
                            <>
                              <thead>
                                <tr>
                                  <td className="py-2">PRODUCT</td>
                                  <td className="py-2">PRICE</td>
                                  <td className="py-2 text-center">QTY</td>
                                  <td className="py-2 text-center">Total</td>
                                </tr>
                              </thead>
                              <tr key={i}>
                                <td className="py-3 border-t-2 border-[#F6F7F8]">
                                  <div className="text-left">
                                    <p className="mb-2">
                                      {product?.product_name}
                                    </p>
                                    {product?.product_attributes?.length > 0 &&
                                      product?.product_attributes?.map(
                                        (attr, i) => (
                                          <p key={i} className="text-sm">
                                            {attr.quantity}x {attr?.title} - NGN{" "}
                                            {attr.amount.toFixed(2)}
                                          </p>
                                        )
                                      )}
                                  </div>
                                </td>
                                <td className="py-3 border-t-2 border-[#F6F7F8]">
                                  NGN{" "}
                                  {Number(product?.product_amount.toFixed(2)) /
                                    Number(product.quantity)}
                                </td>
                                <td className="py-3 border-t-2 border-[#F6F7F8]">
                                  <div className="bg-[#F6F7F8] text-center">
                                    {product.quantity}
                                  </div>
                                </td>
                                <td className="py-3 border-t-2 border-[#F6F7F8] text-center">
                                  NGN{" "}
                                  {getTotalItemPrice(
                                    product,
                                    product?.product_amount
                                  )}
                                </td>
                              </tr>
                            </>
                          ))
                      )}

                    {pay_for_me?.data?.featured?.length > 0 &&
                      pay_for_me?.data?.featured?.map((product, i) => (
                        <>
                          <thead>
                            <tr>
                              <td className="py-2">PRODUCT ID</td>
                              <td className="py-2">DURATION</td>
                              <td className="py-2 text-center">PRICE</td>
                              <td className="py-2 text-center">Total</td>
                            </tr>
                          </thead>
                          <tr key={i}>
                            <td className="py-3 border-t-2 border-[#F6F7F8]">
                              <div className="text-left">
                                <p className="mb-2">{product?.product_id}</p>
                              </div>
                            </td>
                            <td className="py-3 border-t-2 border-[#F6F7F8]">
                              <div className="bg-[#F6F7F8] text-center w-fit py-1 px-2">
                                {product?.featured_duration_in_hours} Hours
                              </div>
                            </td>
                            <td className="py-3 border-t-2 border-[#F6F7F8] text-center">
                              ₦{numberWithCommas(product?.featured_amount)}
                            </td>
                            <td className="py-3 border-t-2 border-[#F6F7F8] text-center">
                              ₦{numberWithCommas(product?.featured_amount)}
                            </td>
                          </tr>
                        </>
                      ))}
                  </table>
                )}
              </section>
            </LeftColumn>

            <RightColumn>
              <section aria-labelledby="section-2-title">
                <h2 className="sr-only" id="section-2-title">
                  Section title
                </h2>
                <div className="rounded-lg bg-white dark:bg-[#121212] overflow-hidden shadow">
                  <div className="p-6">
                    <div className="mb-5">
                      <div className="flex justify-between items-center mb-4">
                        <label>Subtotal:</label>
                        <span>
                          NGN {numberWithCommas(pay_for_me?.data?.amount)}
                        </span>
                      </div>
                      <div className="border-t border-[#F6F7F8] mb-4" />

                      <div className="flex justify-between items-center mb-4 text-[30px]">
                        <label>Total:</label>
                        <span className="font-bold">
                          NGN {numberWithCommas(pay_for_me?.data?.amount)}
                        </span>
                      </div>
                    </div>

                    <Input.Label
                      title={""}
                      placeholder={"Enter email address for receipt (Optional)"}
                      className={
                        "mt-8 py-6 w-full border border-[#DADADA] focus:border-primary focus:ring-0 sm:text-sm rounded-md"
                      }
                      onChange={(e) => setReceiptEmail(e.target.value)}
                    />

                    <Button.Primary
                      title={"Check out"}
                      className={"w-full h-[48px] px-4 mt-3 mb-2"}
                      onClick={() => {
                        paymentModalRef.current.open();
                        paymentModalRef.current.getTotalPrice(
                          pay_for_me?.data?.amount
                        );
                        paymentModalRef.current.getReference(
                          pay_for_me?.data?.id
                        );
                      }}
                    />
                  </div>
                </div>
                <PaymentModal2
                  ref={paymentModalRef}
                  payment_type={"collect"}
                  visibility={false}
                  receipt_email={receipt_email}
                />
              </section>
            </RightColumn>
          </div>
        )}
      </Container>

      {pay_for_me?.status === 404 && (
        <Container>
          <div
            className="bg-center bg-no-repeat bg-cover"
            style={{ backgroundImage: `url(${ErrorBg})` }}
          >
            <div className="flex flex-col sm:flex-row w-full justify-center items-center gap-4">
              <img src={Image404} alt="404" />
              <div className="max-w-[600px] text-center">
                <h3 className="text-7xl font-bold mb-2">Oops!</h3>
                <h4 className="text-3xl mb-5 dark:text-[#BCBCBC]">
                  We couldn’t find the page, you were looking for.
                </h4>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
