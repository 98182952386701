import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import TableSkeleton from "../../../../../components/skeleton/table";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/icons/copy-outline.svg";
import { getARestaurantOrderAction } from "../../../../../redux/restaurantOrder/restaurantOrderActions";
import { copyTextToClipboard } from "../../../../../components/helpers";
import { showAPayment } from "../../../../../redux/payment/paymentActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleRestaurantOrder() {
  const dispatch = useDispatch();
  const params = useParams();
  let [pfmLink, setPfmLink] = React.useState("");

  const { isLoading, order } = useSelector((state) => state.restaurantOrder);
  const { singlePayment } = useSelector((state) => state.payment);

  useEffect(() => {
    if (params?.id) {
      dispatch(getARestaurantOrderAction(params?.id));
    }
    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    if (order?.payment_id) {
      dispatch(showAPayment(order?.payment_id));
    }
  }, [dispatch, order?.payment_id]);

  useEffect(() => {
    if (singlePayment?.pfm) {
      let base_url = window.location.origin;
      let text = `${base_url}/pay-for-me/${singlePayment?.pfm}`;
      setPfmLink(text);
    }
  }, [singlePayment?.pfm]);

  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 gap-8 pt-6">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md px-4 sm:px-8 py-5">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6 pb-16">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {moment(order?.created_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                  <span className="flex items-center">
                    #
                    {order?.id?.substr(0, 8) +
                      "...." +
                      order?.id?.substr(order?.id?.length - 10)}
                    <button
                      type="button"
                      onClick={() => {
                        copyTextToClipboard(order?.id);
                      }}
                    >
                      <CopyIcon className="h-4 w-4 ml-1" />
                    </button>
                  </span>
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Insurance Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(order?.order_insurance_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Status
                </label>

                <p className="text-sm font-bold capitalize">
                  {order?.status === "delivered" ? (
                    <span className="text-[#00D220] font-bold">
                      {order?.status}
                    </span>
                  ) : order?.status === "shipped" ? (
                    <span className="text-[#046ECF] font-bold">
                      {order?.status}
                    </span>
                  ) : (
                    <span className="text-primary font-bold">
                      {order?.status}
                    </span>
                  )}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Payment Status
                </label>
                <p className="text-primary text-sm font-bold">
                  {order?.order_paid ? (
                    <span className="text-[#00D220] font-bold">Paid</span>
                  ) : (
                    <span className="text-primary font-bold">Unpaid</span>
                  )}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Recipient City
                </label>
                <p className="text-sm font-bold">{order?.recipient_city}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Payment Link
                </label>
                <div className="flex gap-1 max-w-[160px] w-full items-center justify-between">
                  <span className="text-sm font-bold text-[#1634A1] truncate">
                    {pfmLink}
                  </span>
                  <CopyIcon
                    className="h-4 w-4 cursor-pointer flex-none"
                    onClick={() => copyTextToClipboard(pfmLink)}
                  />
                </div>
              </div>
            </div>
          </div>

          {order?.order_coupons?.length > 0 && (
            <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Coupon Details</h3>
              </div>

              {order?.order_coupons?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Title</label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {order?.order_promotion_codes?.length > 0 && (
            <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Promo Code Details</h3>
              </div>

              {order?.order_promotion_codes?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Title</label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] dark:border-transparent bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium">
                Product Details (Optional)
              </h3>
            </div>

            {isLoading ? (
              <TableSkeleton />
            ) : (
              order?.order_items?.length > 0 &&
              order?.order_items?.map((product, i) => (
                <div key={i}>
                  <div className="flex justify-between items-start mb-5">
                    <div className="flex">
                      <span className="w-6 mr-3 sm:mr-10">
                        {product.quantity}x
                      </span>
                      <div>
                        <p className="mb-1">{product?.product_name}</p>
                        {product?.product_attributes?.length > 0 &&
                          product?.product_attributes?.map((attr, i) => (
                            <p key={i} className="text-sm text-[#676565]">
                              {attr.quantity}x {attr?.name} - NGN {attr.amount}
                            </p>
                          ))}
                      </div>
                    </div>
                    <p>NGN {product?.product_amount}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
