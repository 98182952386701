import React from "react";
import moment from "moment";

export default function CountdownTimer({ order }) {
  const TIME_LIMIT = 0;
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;
  const FULL_DASH_ARRAY = 1015;
  let timerInterval = null;

  let ogwugoTime = order?.updated_at;
  let extraMinutes = 40;
  let foodPreparationTime = order?.foods?.[0]?.food_preparation_time;
  const currentTime = moment();

  // Parse the given time string
  let parsedTime = moment(ogwugoTime, moment.ISO_8601);
  // Parse the food preparation time and convert to minutes
  const [prepHours, prepMinutes] = (foodPreparationTime || "00:00")
    ?.split(":")
    ?.map(Number);
  const totalPrepMinutes = prepHours * 60 + prepMinutes;

  // Add the total preparation minutes and extra minutes to the parsed time
  parsedTime = parsedTime.add(totalPrepMinutes + extraMinutes, "minutes");

  // Calculate the difference in milliseconds
  const difference = parsedTime.diff(currentTime);

  // If the difference is negative (past), return 0, otherwise return the difference in seconds
  const timeDifferenceInSeconds = Math.round(
    difference > 0 ? difference / 1000 : 0
  );

  function startTimer() {
    // Get Unix timestamps
    const parsedTimeUnix = parsedTime.valueOf();
    const currentTimeUnix = currentTime.valueOf();

    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;

      if (currentTimeUnix > parsedTimeUnix) {
        timeLeft = 0;
      } else {
        timeLeft = timeDifferenceInSeconds - timePassed;
      }

      if (document.getElementById("base-timer-label")) {
        document.getElementById("base-timer-label").innerHTML =
          formatTimeLeft(timeLeft);
        setCircleDasharray();
      }

      if (timeLeft === 0 || timeDifferenceInSeconds === 0) {
        onTimesUp();
      }
    }, 1000);
  }

  function onTimesUp() {
    clearInterval(timerInterval);
  }

  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / timeDifferenceInSeconds;
    return (
      rawTimeFraction - (1 / timeDifferenceInSeconds) * (1 - rawTimeFraction)
    );
  }

  // Update the dasharray value as time passes, starting with 1015
  function setCircleDasharray() {
    const circleDasharray = `${(
      calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)}`;
    document
      .getElementById("base-timer-path-remaining")
      .setAttribute("stroke-dashoffset", circleDasharray);
  }

  function formatTimeLeft(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }
  startTimer();

  return (
    <div
      className="relative bg-white dark:bg-[#121212] rounded-full shadow-md sm:rounded-full border pl-2  pt-1 max-w-[348px] h-[340px] mx-auto"
      data-test="timerComponent"
    >
      <svg
        width="331"
        height="332"
        viewBox="0 0 331 332"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="transform -scale-x-100 "
      >
        <circle
          cx="165.66"
          cy="165.755"
          r="161.309"
          stroke="#9A9898"
          strokeWidth="8"
          strokeDasharray="36 10"
        />
        <circle
          id="base-timer-path-remaining"
          cx="165.66"
          cy="165.755"
          r="161.309"
          strokeWidth="9"
          stroke="#CE2600"
          strokeDasharray="1015"
          strokeDashoffset={1015}
          className="progress "
        />
      </svg>
      <span
        id="base-timer-label"
        className=" absolute top-28 left-24 flex items-center justify-center font-bold text-6xl text-primary"
      >
        {formatTimeLeft(timeLeft)}
      </span>
      <span className="absolute top-48 left-24 max-w-[153px] text-2xl font-medium text-center">
        Minutes until delivered
      </span>
    </div>
  );
}
