import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../components/buttons/buttons";
import Input from "../../../../../components/inputs/inputs";
import { Beat } from "../../../../../plugins/spinners.plugin";
import {
  getMyBusinessSettings,
  editBusinessSettings,
} from "../../../../../redux/businessSettings/businessSettingsActions";

export default function BusinessFinancials() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updating, myBusinessSettings } = useSelector(
    (state) => state.businessSettings
  );

  const [businessData, setBusinessData] = useState({
    business_id: myBusinessSettings?.business_id,
    currency: myBusinessSettings?.currency,
    insurance_percentage: Math.trunc(myBusinessSettings?.insurance_percentage),
    tax_percentage: Math.trunc(myBusinessSettings?.tax_percentage),
    discount_percentage: Math.trunc(myBusinessSettings?.discount_percentage),
    vat_percentage: Math.trunc(myBusinessSettings?.vat_percentage),
  });

  useEffect(() => {
    dispatch(getMyBusinessSettings());
    // eslint-disable-next-line
  }, []); 

  useEffect(() => {
    setBusinessData({
      business_id: myBusinessSettings?.business_id,
      currency: myBusinessSettings?.currency,
      insurance_percentage: Math.trunc(
        myBusinessSettings?.insurance_percentage
      ),
      tax_percentage: Math.trunc(myBusinessSettings?.tax_percentage),
      discount_percentage: Math.trunc(myBusinessSettings?.discount_percentage),
      vat_percentage: Math.trunc(myBusinessSettings?.vat_percentage),
    });
  }, [myBusinessSettings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setBusinessData({ ...businessData, [name]: value });
  };

  const handleSubmit = async () => {
    const newInput = { ...businessData };

    try {
      const response = await dispatch(editBusinessSettings(newInput));
      if (response.status === "success") {
        dispatch(getMyBusinessSettings());
        navigate(-1);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="flex justify-center items-center mx-auto">
      <div className="sm:w-[976px] w-full sm:border dark:border-none px-6 bg-white dark:bg-[#121212] sm:border-gray-200 ">
        <div className="border-b-2 py-4 mb-6 flex justify-between ">
          <p className=" text-base sm:mt-6 font-bold text-gray-800 dark:text-white">
            Business Financials
          </p>
          <Button.Primary
            title={updating ? <Beat /> : "Save Changes"}
            type={"button"}
            form={"b_form"}
            className={"w-[127px] rounded-sm sm:mt-4"}
            onClick={(e) => handleSubmit(e)}
          />
        </div>
        {businessData && (
          <form id="b_form" className="sm:px-10 max-w-lg">
            <div>
              <Input.Select
                title={"Currency"}
                htmlFor="currency"
                name="currency"
                defaultValue={businessData?.currency}
                onChange={(e) => handleChange(e)}
              >
                <option>Select Currency</option>
                <option value={"USD"}>USD</option>
                <option value={"NGN"}>NGN</option>
              </Input.Select>
            </div>

            <div className="mt-4">
              <Input.Number
                title={"Insurance Percentage(%)"}
                htmlFor="insurance_percentage"
                name="insurance_percentage"
                placeholder="Enter amount"
                defaultValue={businessData?.insurance_percentage}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="mt-4">
              <Input.Number
                title={"Tax Percentage(%)"}
                htmlFor="tax_percentage"
                name={"tax_percentage"}
                placeholder="Enter amount"
                defaultValue={businessData?.tax_percentage}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="mt-4">
              <Input.Number
                title={"Discount Percentage(%)"}
                name={"discount_percentage"}
                htmlFor={"discount_percentage"}
                placeholder="Enter amount"
                defaultValue={businessData?.discount_percentage}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="mt-4">
              <Input.Number
                title={"VAT Percentage(%)"}
                name={"vat_percentage"}
                htmlFor={"vat_percentage"}
                placeholder="Enter amount"
                defaultValue={businessData?.vat_percentage}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
