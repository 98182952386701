import React, { useState } from "react";
import StoreBusiness from "./storeBusiness";
import RestaurantBusiness from "./restaurantBusiness";

const tabs = [
  { name: "Restaurant", href: "business_restaurant" },
  { name: "Store", href: "business_store" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BusinessTable({ myBusiness }) {
  const [active_tab, setActiveTab] = useState("business_restaurant");

  const toggle = (tab) => {
    if (active_tab !== tab.href) {
      setActiveTab(tab.href);
    }
  };

  return (
    <div>
      <div>
        <div className=" px-8 ">
          <div className="sm:block">
            <div>
              <nav
                className="-mb-px flex space-x-4 overflow-x-auto"
                aria-label="Tabs"
              >
                {tabs.map((singleTab) => (
                  <button
                    key={singleTab.name}
                    onClick={() => {
                      toggle(singleTab);
                    }}
                    className={classNames(
                      singleTab.href === active_tab
                        ? "border-primary text-primary font-bold"
                        : "border-transparent text-gray-500 dark:text-[#BCBCBC] hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      singleTab.href === active_tab ? "page" : undefined
                    }
                  >
                    {singleTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div>
        {active_tab === "business_store" && (
          <StoreBusiness myBusiness={myBusiness} />
        )}
        {active_tab === "business_restaurant" && (
          <RestaurantBusiness myBusiness={myBusiness} />
        )}
      </div>
    </div>
  );
}
