import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import Button from "../../../../components/buttons/buttons";
import TokeModal from "../../../../components/modals/admin/TokenModal";
import { copyTextFromTable } from "../../../../components/helpers";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
import { Pagination } from "../../../../components/pagination/pagination";
import { getMyThirdPartyToken } from "../../../../redux/thirdParty/thirdPartyActions";

export default function Token() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const { myTokens, isLoading } = useSelector((state) => state.thirdParty);

  useEffect(() => {
    dispatch(getMyThirdPartyToken());
  }, [dispatch]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((featured) => {
        let show = {
          label: featured?.label,
          date: moment(featured?.created_at).format("MMM DD, YYYY"),
          time: moment(featured?.created_at).format("HH:MMa"),
          key: (
            <span>
              {featured?.token.slice(0, 32) + "..."}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, featured?.token);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }
  useEffect(() => {
    const dataSet = createTableData(myTokens?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTokens]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        columns: [
          {
            Header: "Label",
            accessor: "show.label",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Time",
            accessor: "show.time",
          },
          {
            Header: "Key",
            accessor: "show.key",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="token-page">
      <div className="bg-white dark:bg-[#121212] rounded-sm px-4 sm:px-8 py-5">
        <div className="flex justify-between">
          <p className=" text-sm font-bold text-gray-500 dark:text-[#BCBCBC] sm:max-w-[500px]">
            This shows a third party token to create and edit orders for your
            company using the generated token.
          </p>
          <div className="hidden sm:block">
            <Button.Primary title={" + Create Token"} onClick={openModal} />
          </div>
        </div>
        <TokeModal show={isOpen} onClose={closeModal} />
      </div>
      <div className="border-b border-[#E4E4F3]"></div>
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full"
            onClick={() => {}}
          />
        )}
        <Pagination />
      </div>
    </div>
  );
}
