import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Beat } from "../../../../plugins/spinners.plugin";
import Input from "../../../inputs/inputs";
import Button from "../../../buttons/buttons";
import CLOSE from "../../../../assets/images/icons/close.svg";
import { setNewAttributeAction } from "../../../../redux/attribute/attributeActions";
import { getUserFromCookies } from "../../../../utils/Auth";
import { filterAttributeAction } from "./../../../../redux/attribute/attributeActions";

export default function CreateFoodVariationModal(props) {
  const { food_business_id } = props;
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.attribute);
  const [data, setData] = useState({
    business_id: "",
    title: "",
    type: "restaurant",
    name: "",
    amount: "",
  });
  const { title, name, amount } = data;
  const user = getUserFromCookies();
  let business = Object.keys(user?.groups)[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    // check if a food_business_id exists checks if a business_id was sent 
    // from edit page. Else, get business id from the user details
    if (title && name && name?.length > 3 && amount) {
      try {
        const response = await dispatch(
          setNewAttributeAction({
            ...data,
            business_id: food_business_id ? food_business_id : business,
          })
        );
        if (response?.status === "success") {
          dispatch(
            filterAttributeAction({
              business_id: food_business_id ? food_business_id : business,
            })
          );
          props.onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="Coupon" data-test="couponModal">
      <Transition appear show={props.show}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-white dark:bg-[#121212] w-[380px] rounded-md text-left sm:w-[750px] p-4 sm:p-6">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3 "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-5 text-center sm:mt-2 sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="h3"
                        className="text-xl text-black dark:text-white"
                      >
                        Add product attribute
                      </Dialog.Title>
                    </div>
                  </div>

                  <form className="py-5 mt-4 px-5">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-6">
                      <Input.Label
                        title={"Title"}
                        type="text"
                        name="title"
                        id="title"
                        className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="E.g: Swallow"
                        onChange={handleChange}
                      />

                      <div>
                        <Input.Label
                          title={"Variation Name"}
                          type="text"
                          name="name"
                          id="name"
                          className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                          placeholder="E.g: Garri"
                          onChange={handleChange}
                        />
                        {name && name.length < 4 && (
                          <div className="text-xs text-red-500">
                            Name must be more than 4 characters
                          </div>
                        )}
                      </div>

                      <Input.Number
                        title={"Value (NGN)"}
                        type="number"
                        name="amount"
                        id="amount"
                        className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="E.g: 200"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="py-8 max-w-3xl mx-auto text-center">
                      <Button.Primary
                        title={
                          isLoading ? (
                            <Beat color={"#ffffff"} />
                          ) : (
                            "Create Variation"
                          )
                        }
                        className="px-4"
                        onClick={handleSubmit}
                        type="button"
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
